import React from "react";
import {Skeleton} from "@mui/material";
import './index.scss'

export default function SMainMenu() {
    return (
        <div className={'SkeletonContainer'}>
            <Skeleton variant={'text'} height={32}/>
            <Skeleton variant={'text'} height={30}/>
            <Skeleton variant={'text'} height={30}/>
            <Skeleton variant={'text'} height={28}/>
        </div>
    )
}