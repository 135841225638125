import React, {useEffect, useState} from "react";
import {Paper, Popper} from '../../shared'
import {Stack, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {autoSizeColumnsBasedOnData} from "./utls";
import {DensityLargeRounded, DensityMediumRounded, DensitySmallRounded} from "@mui/icons-material";

export default function Settings(props: ISettings) {
    const {anchorEl, gridApi, gridColumnApi} = {...props}
    const [tableWidth, setTableWidth] = useState('dense')

    const handleTableWidthChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setTableWidth(newAlignment);
        }
    };


    function autoSizeAll(skipHeader: boolean) {
        const allColumnIds: any = [];
        gridColumnApi.getAllColumns().forEach((column: any) => {
            allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    useEffect(() => {

        if (gridApi) {
            if (tableWidth === 'dense') autoSizeAll(false)
            if (tableWidth === 'medium') autoSizeColumnsBasedOnData(gridApi, gridColumnApi)
            if (tableWidth === 'large') autoSizeAll(true)
        }
    }, [tableWidth])

    return (
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
            <Paper sx={{p: 1, boxShadow: 'var(--fq-box-shadow-2)', zIndex: 10000}} variant={'elevation'}>
                <Stack direction="row" spacing={4}>
                    <ToggleButtonGroup
                        size={'small'}
                        value={tableWidth}
                        exclusive
                        onChange={handleTableWidthChange}
                        aria-label="text alignment"
                    >

                        <ToggleButton value="dense" aria-label="left aligned">
                            <Tooltip title={'Auto size columns'}>
                                <DensitySmallRounded style={{transform: 'rotate(90deg)'}}/>
                            </Tooltip>
                        </ToggleButton>

                        <ToggleButton value="medium" aria-label="centered">
                            <Tooltip title={'Auto size with intelligence '}>
                                <DensityLargeRounded style={{transform: 'rotate(90deg)'}}/>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="large" aria-label="right aligned">
                            <Tooltip title={'Auto size with with header skip'}>
                                <DensityMediumRounded style={{transform: 'rotate(90deg)'}}/>
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </Paper>
        </Popper>
    )
}


interface ISettings {
    anchorEl: any,
    setAnchorEl: any,
    gridApi: any,
    gridColumnApi: any
}