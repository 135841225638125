import { format } from 'sql-formatter'

export default function useBuildVisualQuery() {
    const createQuery = (visualQueryState: any) => {
        const objectName = visualQueryState?.object.name
        const fields = visualQueryState?.fields
        const limit = visualQueryState?.limit
        const offset = visualQueryState?.offset
        const filter = visualQueryState?.filter
        const orderByField = visualQueryState?.orderBy?.field?.name
        const orderByDirection = visualQueryState?.orderBy?.direction
        const orderByNullPosition = visualQueryState?.orderBy?.nullPosition
        if (!objectName) return ''

        const queryBase = {
            start: 'SELECT',
            from: 'FROM',
            order: 'ORDER BY',
            nulls: 'NULLS',
            limit: 'LIMIT',
            offset: 'OFFSET'
        }
        let query = ''
        query = `${queryBase.start} ${fields.map((e: any) => e.name).join(', ')} ${queryBase.from} ${objectName}`


        if (filter?.length > 0) {
            query += buildFilterBlock(filter)
        }
        if (orderByField) {
            query += ` ${queryBase.order} ${orderByField} ${orderByDirection || 'ASC'} ${queryBase.nulls} ${orderByNullPosition}`
        }
        query += ` ${queryBase.limit} ${limit} ${queryBase.offset} ${offset}`
        return format(query, { indent: '    ' }) || query
    }

    return createQuery
}


function buildFilterBlock(order: any[]) {
    let orderBlock = ''

    if (!order[0]?.value) return ''
    order.forEach((e: any) => {
        if (e.value) {
            const newValue = valueBuilder(e.value, e.field.soapType)
            orderBlock += `${e.id !== 1 ? e.combinator : ''} ${e.field.name} ${e.operator.operator} ${newValue}`
        }
    })
    return ` WHERE ${orderBlock}`
}


function valueBuilder(value: any, fieldType: string): string {
    if (fieldType.endsWith('string')) {
        if (Array.isArray(value)) {
            return value.map((singleValue: string) => valueBuilder(singleValue, fieldType)).join(', ')
        }
        return `'${value}'`
    }
    return value
}