import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CircularProgress, Stack} from "@mui/material";
import {Button} from "../../shared";
import {apiErrorProgress} from "../../helper";
import {useAuth, useSalesforceInstance} from "../../hooks";
import {routes} from "../../constants/routes";
import service from "../../services";
import salesforceLogo from "../../assets/images/salesforce_logo.svg";

export default function OAuth(props: IOAuth) {
    const {salesforceEnvironment, customUrl} = {...props}
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const {setSalesforceInstance} = useSalesforceInstance()
    const {setLoggedIn} = useAuth()
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params:{loginUrl: string} = {loginUrl: ''}
            if (salesforceEnvironment === 'production') params['loginUrl'] = 'https://login.salesforce.com'
            if (salesforceEnvironment === 'sandbox') params['loginUrl'] = 'https://test.salesforce.com'
            if (salesforceEnvironment === 'custom') params['loginUrl'] = customUrl
            const {data: {data}} = await service.salesforceAuth().salesforceOAuth(params)
            return data
        } catch (e) {
            const error = apiErrorProgress(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchRequestTokenData = async (accessToken: string) => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {authorizationCode: accessToken}
            const {data} = await service.salesforceAuth().salesforceOAuthIssueToken(params)
            setSalesforceInstance(data.data.instanceUrl)
            setLoggedIn(true)
            navigate(routes.DASHBOARD)
        } catch (e) {
            const error = apiErrorProgress(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }


    const _attachEvent = () => {
        _detachEvent()
        window.addEventListener('message', receiveMessage, false)
    }

    const _detachEvent = () => {
        window.removeEventListener('message', receiveMessage, false);
    }


    function receiveMessage(event: any) {
        if(!event.isTrusted) return alert('untrusted request')
        if (event?.data?.source === '__self') fetchRequestTokenData(event.data.authorizationCode).then()
    }

    const handleOnClickEvent = () => {
        const openRef = window.open('', 'popup', 'toolbar=no, menubar=no, width=500, height=650, top=100, left=100') || {location: {href: ''}}
        fetchData().then((e) => openRef.location.href = e)
    }


    useEffect(() => {
        _attachEvent()
        return () => {
            _detachEvent()
        }
    }, [])

    return (
        <React.Fragment>
            <Button variant={'outlined'}
                    color={'inherit'}
                    onClick={handleOnClickEvent}
                    disabled={apiProgress.loading}
                    style={{borderColor: '#adadad'}}
                    sx={{borderRadius: '10px', height: 45, color: 'text.secondary'}}>

                {
                    apiProgress.loading ? <CircularProgress color={'inherit'} size={24}/> : <ButtonInnerContent/>
                }
            </Button>
        </React.Fragment>
    )
}


function ButtonInnerContent(){
    return(
        <Stack alignItems={'center'} direction={'row'}>
            <img src={salesforceLogo}
                 alt={'salesforce-logo'}
                 style={{height: 24, marginRight: 16}}/>
            Login with Salesforce
        </Stack>
    )
}



interface IOAuth {
    salesforceEnvironment: string,
    customUrl: string
}