import { useEffect } from 'react';
import { Grid } from '@mui/material';
import HomeMainContent from "./HomeMainContent";
import Features from "./Features";
import StartUsingNow from "./StartUsingNow";
import { Product } from './Product';

import screenShot from '../../assets/images/query.png'
import storageIntegration from '../../assets/images/storage-integration.png'

import './index.scss'

export default function Home() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='home-container'>
            <Grid container
                alignItems={'center'}
                className={'width-max-adjust padding-wrapper welcome-mockup'}>
                <Grid item xs={12} lg={6}>
                    <HomeMainContent />
                </Grid>
                <Grid item xs={12} lg={6} height={'100%'}>
                    <div className={'image-wrapper'}>
                        <img src={screenShot} alt={'force-query'} draggable={false} />
                    </div>
                </Grid>
            </Grid>
            <Features />
            <div className={'colored-home-section padding-wrapper'}>
                <Product />
                {/* <WorkFlowInShort /> */}
                <StartUsingNow />
            </div>
        </div>
    )
}


