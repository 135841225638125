import React, { useEffect, useState } from "react";
import { DeleteOutline } from "@mui/icons-material";
import { Chip, IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import { Autocomplete, Button, TextFieldAutocomplete } from "../../shared";
import { comparisonOperator } from "../../constants";
import './index.scss'

export default function ConditionBlock(props: IConditionBlock) {
    const { filterRules, onFilterChange, fields } = { ...props }
    const [rules, setRules] = useState<any[]>([])

    const handleValueChange = (source: string, value: any, id: number) => {
        setRules((current: any) => {
            const itemIndex = current.findIndex((e: any) => e.id === id)
            if (itemIndex === -1) return current

            if (source === 'operator' && value?.label === 'IN') {
                current[itemIndex]['value'] = [current[itemIndex]['value']]
            }
            current[itemIndex][source] = value
            return [...current]
        })
    }

    const handleNewBlockAdd = () => {
        setRules(current => {
            const lastId = current.reduce((acc, curr) => {
                if (curr.id >= acc) acc = curr.id
                return acc
            }, 0)
            current.push({ id: lastId + 1, field: '', combinator: 'AND', value: '', operator: '' })
            return [...current]
        })
    }

    const handleRemoveBlock = (id: number) => {
        setRules(current => current.filter((e: any) => e.id !== id))
    }


    useEffect(() => {
        setRules([])
    }, [fields])

    useEffect(() => {
        onFilterChange((current: any) => ({ ...current, filter: rules }))
    }, [rules])

    return (
        <div className={'ConditionBlock-wrapper'}>
            <ConditionTitle />
            <div className={'cw-wrapper'}>
                {
                    rules.map((e: any) => <ConditionSingleBlock
                        fields={fields}
                        key={e.id}
                        onDeleteClick={handleRemoveBlock}
                        onValueChange={handleValueChange}
                        {...e}
                    />)
                }
            </div>
            <div className={'cw-new-block'}>
                <Button
                    disabled={fields.length < 1}
                    onClick={handleNewBlockAdd}
                    title={'Add order to query'}
                    sx={{
                        borderRadius: 2.5,
                        padding: '0.5rem',
                        background: 'var(--fq-background-color-2)',
                    }}
                    size={'small'}>
                    NEW FILTER
                </Button>
            </div>
        </div>
    )
}

function ConditionTitle() {
    return (
        <div className={'cw-condition-block cw-condition-title'}>
            <Typography color={'text.secondary'} variant={'body2'}>
                Filters
            </Typography>
        </div>
    )
}

function ConditionSingleBlock(props: IConditionSingleBlock) {
    const {
        field,
        operator,
        value,
        combinator,
        onValueChange,
        onDeleteClick,
        id,
        fields
    } = { ...props }

    const handleChange = (sourceEvent: any, value: any) => {
        onValueChange(sourceEvent, value, id)
    }

    const handleDelete = () => {
        onDeleteClick(id)
    }

    return (
        <div>
            {id !== 1 &&
                <Combinator combinator={combinator} onCombinatorChange={(e) => handleChange('combinator', e)} />
            }
            <div className={'cw-condition-block'}>
                <div className={'cw-field'}>
                    <Autocomplete
                        disablePortal
                        onChange={(e: any, newValue: any) => handleChange('field', newValue)}
                        size={'small'}
                        value={field}
                        options={fields}
                        sx={{ width: '100%' }}
                        renderInput={(params: any) => (
                            <TextFieldAutocomplete
                                {...params}
                                placeholder="Field"
                            />
                        )}
                    />
                </div>
                <div className={'cw-operator'}>
                    <Autocomplete
                        value={operator}
                        disablePortal
                        onChange={(e: any, newValue: any) => handleChange('operator', newValue)}
                        size={'small'}
                        options={comparisonOperator.string}
                        sx={{ width: '100%' }}
                        renderInput={(params: any) => (
                            <TextFieldAutocomplete
                                {...params}
                                placeholder="Operator"
                            />
                        )}
                    />
                </div>
                <div className={'cw-value-selector'}>
                    <ConditionValueComponent value={value} onChange={handleChange} type={operator?.label || ''} />
                </div>
                <IconButton
                    onClick={handleDelete}
                    style={{ border: '1px solid var(--fq-border-color-1)' }}
                    sx={{
                        borderRadius: 2,
                        height: 36,
                        width: 36
                    }}>
                    <DeleteOutline />
                </IconButton>
            </div>
        </div>
    )
}


function ConditionValueComponent(props: IConditionValueComponent) {
    const { type = '', onChange, value } = { ...props }
    if (type?.toLowerCase() === 'in') {
        return (
            <Autocomplete
                value={value}
                disablePortal
                freeSolo
                onChange={(e: any, newValue: any) => onChange('value', newValue)}
                size={'small'}
                multiple
                options={[]}
                renderTags={(value: readonly string[], getTagProps: any) =>
                    value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} size={'small'} />
                    ))
                }
                sx={{ width: '100%' }}
                renderInput={(params: any) => (
                    <TextFieldAutocomplete
                        {...params}
                        placeholder="Values"
                    />
                )}
            />
        )
    }
    return <TextFieldAutocomplete
        value={value}
        placeholder="Value"
        style={{ width: '100%' }}
        onChange={(e: any) => onChange('value', e.target.value)}
    />
}

function Combinator(props: ICombinator) {
    const { combinator, onCombinatorChange } = { ...props }

    const handleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
        onCombinatorChange(value)
    }

    const combinatorItems = ['AND', 'OR', 'NOT']
    return (
        <div className={'cw-condition-join-wrapper'}>
            <div className={'cw-condition-join-operator'}>

                <ToggleButtonGroup size={'small'}
                    exclusive
                    className={'cw-condition-join'}
                    value={combinator}
                    onChange={handleChange}>
                    {
                        combinatorItems.map(e => <ToggleButton
                            key={e}
                            value={e}
                            color={'primary'}
                            className={'cw-condition-join-item'}>
                            {e}
                        </ToggleButton>
                        )
                    }
                </ToggleButtonGroup>
            </div>
        </div>
    )
}

interface IConditionBlock {
    filterRules: any,
    onFilterChange: (e: any) => any,
    fields: any
}

interface IConditionValueComponent {
    type: string,
    onChange: (e: string, value: any) => any,
    value: any
}

interface ICombinator {
    combinator: string,
    onCombinatorChange: (e: any) => any
}

interface IConditionSingleBlock {
    field: string,
    fields: any,
    operator: any,
    value: any,
    combinator: string,
    rules?: any[],
    onValueChange: (sourceEvent: any, value: any, id: number) => any,
    onDeleteClick: (id: number) => any,
    id: number
}
