import React, {useState, useEffect} from "react";
import {useAuth, useSalesforceInstance} from "../../hooks";
import {Button, Popper} from "../../shared";
import {ClickAwayListener, Divider, Stack, Typography} from "@mui/material";
import {KeyboardArrowDown, Logout} from "@mui/icons-material";
import service from '../../services'
import './index.scss'
import {apiErrorProgress} from "../../helper";

export default function UserProfile() {
    const {salesforceInstance} = useSalesforceInstance()
    const [anchorEl, setAnchorEl] = useState(null)
    const [userData, setUserData] = useState<IUserIdentity>(INITIAL_VALUE)
    const [apiProgress, setApiProgress] = useState({loading: false, ready: true, error: false})

    const handleClick = (e: any) => {
        if (!anchorEl) {
            setAnchorEl(e.currentTarget)
        } else{
            setAnchorEl(null)
        }

    }

    const fetchUserData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const headers = {
                'x-salesforce-instance-url': salesforceInstance
            }
            const {data: {data}} = await service.salesforceOther().salesforceGetAuthenticatedUser(headers)
            setUserData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorProgress(e)
            setApiProgress({loading: true, ready: false, error: error})
        }
    }

    useEffect(() => {
        fetchUserData().then()
    }, [salesforceInstance])

    return (
        <React.Fragment>
            <div onClick={(e) => handleClick(e)} className={'profile-small'}>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography color={'primary'} className={'profile-small-name'}>
                        {apiProgress.loading && 'Loading...'}
                        {apiProgress.ready && userData?.displayName}
                    </Typography>
                    <KeyboardArrowDown color={'primary'}/>
                </Stack>
            </div>
            <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement={'bottom-end'}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <div className={'profile-dropper'}>
                        <div className={'profile-dropper-block'}>
                            <Typography
                                variant={'h6'}
                                align={'center'}>
                                {apiProgress.loading && 'Loading...'}
                                {apiProgress.ready && userData?.displayName}
                            </Typography>
                            <Typography
                                gutterBottom
                                variant={'body1'}
                                align={'center'}>
                                {apiProgress.loading && 'Loading...'}
                                {apiProgress.ready && userData?.email}
                            </Typography>
                            <Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                align={'center'}>
                                {apiProgress.loading && 'Loading...'}
                                {apiProgress.ready && userData?.userId}
                            </Typography>
                        </div>
                        <Divider sx={{my: 0.5}}/>
                        <div className={'profile-dropper-block'}>
                            <Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                align={'center'}>
                                Org Id:
                                {apiProgress.loading && 'Loading...'}
                                {apiProgress.ready && userData?.organizationId}
                            </Typography>
                        </div>
                        <Divider sx={{my: 0.5}}/>
                        <LogoutButton/>
                    </div>
                </ClickAwayListener>
            </Popper>
        </React.Fragment>
    )
}

function LogoutButton() {
    const {setLoggedIn} = useAuth()
    const {setSalesforceInstance} = useSalesforceInstance()
    const {salesforceInstance} = useSalesforceInstance()

    const handleClick = async (e: any) => {
        salesforceLogout().then()
        setLoggedIn(false)
        setSalesforceInstance('')
    }

    const salesforceLogout = async () => {
        try {
            const headers = {
                'x-salesforce-instance-url': salesforceInstance
            }
            await service.salesforceAuth().salesforceLogout(headers)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Button startIcon={<Logout fontSize="small"/>}
                onClick={handleClick}
                className={'logout-button'}>
            Logout
        </Button>
    )
}

interface IUserIdentity {
    userId: string,
    organizationId: string,
    username: string,
    nickName: string,
    displayName: string,
    email: string,
    firstName: string,
    lastName: string
}

const INITIAL_VALUE = {
    userId: "",
    organizationId: "",
    username: "",
    nickName: "",
    displayName: "",
    email: "",
    firstName: "",
    lastName: ""
}