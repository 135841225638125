import { format } from 'sql-formatter';
import { IQueryTabObject } from "../../interfaces";
import service from "../../services";
import { apiErrorProgress } from "../../helper";

export const handleActiveTabChange = (e: any, value: any, setActiveTab: any) => {
    if (e.currentTarget === e.target) setActiveTab(value)
}

export const handleEditorModeChange = (activeId: number, newEditorValue: string, setQuery: any) => {
    setQuery((current: any) => {
        return current.map((e: any) => {
            if (e.id === activeId) {
                e['editorMode'] = newEditorValue
            }
            return e
        })
    }
    )
}

export const getEditorMode = (activeId: number, queryList: IQueryTabObject[]) => {
    const result = queryList.find((e: IQueryTabObject) => e.id === activeId)
    return result?.editorMode || 'QUERY'
}

export const handleNewQueryAdd = (queryList: IQueryTabObject[], setQueryList: any, setActiveTab: any) => {
    const newQueryId = queryList.reduce((acc: number, current: IQueryTabObject) => {
        if (acc < current.id) return current.id
        return acc
    }, 0)
    setQueryList([...queryList, { id: newQueryId + 1, queryString: fallbackQuery, queryType: 'SOQL', editorMode: 'QUERY' }])
    setActiveTab(newQueryId + 1)
}

export const handleRemoveQuery = (id: number, queryList: IQueryTabObject[], setQueryList: any, setActiveTab: any) => {
    if (queryList.length > 1) {
        setQueryList((current: IQueryTabObject[]) => current.filter((e: IQueryTabObject) => e.id !== id))
        setActiveTab((current: number) => current !== id ? current : current - 1)
    }
}

export const handleQueryFormat = (activeId: number, setQuery: any) => {
    setQuery((current: any) => {
        return current.map((e: any) => {
            if (e.id === activeId) {
                e['queryString'] = format(e['queryString'], { indent: '    ' })
            }
            return e
        })
    }
    )
}

export const handleSetEditorValue = (activeId: number, value: string, queryList: IQueryTabObject[], setQuery: any) => {
    setQuery((current: any) => {
        return current.map((e: any) => {
            if (e.id === activeId) {
                e['queryString'] = value
                if (value
                    .replace(/\/\*[^]*?\*\//g, '')
                    .replaceAll('\n', '')
                    .replaceAll('\t', '')
                    .substring(0, 4).toUpperCase() === 'FIND') e['queryType'] = 'SOSL'
                else e['queryType'] = 'SOQL'
            }
            return e
        })
    }
    )
}

export const getQueryString = (activeId: number, queryList: IQueryTabObject[]) => {
    try {
        const result: IQueryTabObject[] = queryList.filter((e: IQueryTabObject) => e.id === activeId)
        return result[0]['queryString'] || ''
    } catch (e) {
        return ''
    }

}

export const getActiveQueryType = (activeId: number, queryList: IQueryTabObject[]) => {
    try {
        const result: IQueryTabObject[] = queryList.filter((e: IQueryTabObject) => e.id === activeId)
        return result[0]['queryType'] !== 'SOQL'
    } catch (e) {
        return false
    }
}

export const handleQueryTypeChange = (activeId: number, setQuery: any, sosl: boolean) => {
    setQuery((current: any) => {
        return current.map((e: any) => {
            if (e.id === activeId) {
                e['queryType'] = sosl ? 'SOSL' : 'SOQL'
            }
            return e
        })
    }
    )
}

export const buildQueryWithLimitProperties = (limitEnabled: boolean, limit: string | number, activeId: number, queryList: IQueryTabObject[]) => {
    const query = getQueryString(activeId, queryList)
    if (limitEnabled) {
        const newFormed = query.replace(/ limit /ig, 'LIMIT').split('LIMIT')[0]
        if (limit === 'All') return newFormed
        return newFormed.concat(` LIMIT ${limit}`)
    }
    return query
}

export const handleExecuteQuery = async (query: string = '', setApiProgress: any, setQueryResults: any, salesforceInstance: string, sosl: boolean) => {
    try {
        setApiProgress({ loading: true, ready: false, error: false })
        const headers = {
            'x-salesforce-instance-url': salesforceInstance
        }

        const params = {
            queryString: query.replace(/\/\*[^]*?\*\//g, ''),
            queryType: sosl ? 'SOSL' : 'SOQL'
        }
        const { data: { data } } = await service.salesforceOther().salesforceQuery(headers, params)
        setQueryResults(data)
        setApiProgress({ loading: false, ready: true, error: false })
    } catch (e) {
        const error = apiErrorProgress(e)
        setApiProgress({ loading: false, ready: false, error: error })
    }
}

export const initialDataLoad = (setQueryList: any) => {
    setQueryList([{ id: 1, queryString: fallbackQuery, editorMode: 'QUERY' }])
}

const fallbackQuery = ""