import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ShifterStorageMethods, ShifterTransferMethods } from '../../components'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";

export default function Welcome() {
    const navigate = useNavigate()
    const titleFontProperties = { fontWeight: 600, pb: 1 }
    const [selectedStorageProvider, setSelectedStorageProvider] = useState('');


    const handleTransferMethodSelect = (transferMethod: string) => {
        if (transferMethod) {
            navigate(`${routes.SHIFTER_HOME}/${selectedStorageProvider}?transfer=${transferMethod}`)
        }
    }

    return (
        <Grid container sx={{ height: '100%' }} className={'sf-welcome-container'}>
            <Grid item xs={12} md={6} lg={6} className={'welcome-text-container'}>
                <Typography
                    sx={titleFontProperties}
                    variant={'h3'}
                    color={'primary'}
                    style={{ fontSize: 'clamp(3rem, 1.2857rem + 3.5714vw, 4rem)', letterSpacing: "-0.1rem" }}
                    className={'colored-text sf-er-welcome-text'}
                    gutterBottom>
                    Data made fluid. Data-Junction.
                </Typography>
                <Typography
                    variant={'body1'}
                    color={'text.secondary'}
                    className={'sf-er-welcome-text'}
                    gutterBottom>
                    Transform and migrate your data seamlessly with DataJunction - the efficient and user-friendly tool designed to simplify your data migration process. Move and transform your data easily with DataJunction's robust capabilities and flexible functionality.
                </Typography>

            </Grid>
            <Grid item xs={12} lg={6} md={6}>
                <SwitchTransition mode={'out-in'}>
                    <CSSTransition
                        key={selectedStorageProvider ? '1' : '2'}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames="transition"
                    >
                        {
                            !selectedStorageProvider ?
                                <ShifterStorageMethods
                                    onProviderChange={setSelectedStorageProvider}
                                    key={"1"} /> :
                                <ShifterTransferMethods
                                    onBackClick={setSelectedStorageProvider}
                                    onTransferMethodChange={handleTransferMethodSelect}
                                    key={"2"} />
                        }
                    </CSSTransition>
                </SwitchTransition>
            </Grid>
        </Grid >
    )
}