import React from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import {Outlet} from "react-router-dom";
import LoginImage from '../../assets/images/login.jpg'
import './index.scss'

export default function Authentication() {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Box display={'flex'}
             className={'authentication-container'}
             alignItems={'center'}
             justifyContent={'center'}>
            <Outlet/>
            {smallScreen && <img src={LoginImage} alt={'login-illustration'} className={'login-illustration'} draggable={false}/>}
        </Box>
    )
}