import React, {memo} from 'react'
import {Collapse, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {TransitionGroup} from "react-transition-group";
import {useSavedQueries} from "../../../hooks";
import {ISavedQuery} from "../../../interfaces";
import {CloseRounded} from "@mui/icons-material";
import './index.scss'

export default memo(function SavedQuery() {
    return <SavedQueryStack/>
})


function SavedQueryStack() {
    const {queries} = useSavedQueries()

    return (
        <div className={'saved-query'}>
            <ul>
                <TransitionGroup>
                    {
                        queries.map((query: ISavedQuery, idx: number) => <Collapse key={idx}>
                            <SavedSingleQuery {...query}/>
                        </Collapse>)
                    }
                </TransitionGroup>
            </ul>
        </div>
    )
}


function SavedSingleQuery(props: ISavedQuery) {
    const {queryName, queryBody, queryGroup, id, description = ''} = {...props}
    const {setQueries, queries} = useSavedQueries()

    const dragStart = (e: any) => {
        const query = `/**\n* Name - ${queryName}(${queryGroup})\n* Description - ${description}\n**/\n${queryBody}`
        e.dataTransfer.setData('text', `${query}`);
    }

    const handleDeleteQuery = () => {
        setQueries((current: any) => {
            return current.filter((e: any) => e.id !== id)
        })
        localStorage.setItem('fq-sq', JSON.stringify(queries))
    }

    return (
        <li draggable onDragStart={dragStart}>
            <Tooltip title={queryName} arrow>
                <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <div className={queryGroup === 'SOQL' ? 'soql-logo' : 'soql-logo sosl-logo'}>
                            <p>{queryGroup}</p>
                        </div>
                        <Typography variant={'body2'} color={'textSecondary'}>{queryName}</Typography>
                    </Stack>
                    <IconButton size={'small'} onClick={handleDeleteQuery}>
                        <CloseRounded fontSize={'small'}/>
                    </IconButton>
                </Stack>
            </Tooltip>
        </li>
    )
}