import React, {createContext} from "react";


const AuthContext: React.Context<IAuthContext> = createContext<IAuthContext>({
    loggedIn: false,
    setLoggedIn: () => {
    }
});
export default AuthContext;


interface IAuthContext {
    loggedIn: boolean,
    setLoggedIn: (e: any) => any
}