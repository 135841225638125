import { Paper } from "../../shared";
import { Typography } from "@mui/material";
import { KeyboardArrowRightRounded } from "@mui/icons-material";
import { IStorageOption } from "../../interfaces";
import './index.scss'

export default function StorageOption(props: IStorageOption) {
    const { icon, storageProviderName, onClick, apiName } = { ...props }

    const handleOnClick=()=>{
        onClick(apiName)
    }

    return (
        <div className={'storage-option-container'} onClick={handleOnClick}>
            <Paper variant={'outlined'} className={'storage-option-wrapper'}>
                <div className={'so-i-w'}>
                    <img src={icon} alt={'storage-provider-logo'} />
                </div>
                <Typography variant={'h6'} className={'so-p-n'}>
                    {storageProviderName}
                </Typography>
                <KeyboardArrowRightRounded className="so-icon" />
            </Paper>
        </div>
    )
}