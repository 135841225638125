import {VideoPlayer} from "../../components";
import {Modal} from "../../shared";
import React from "react";
import {IconButton} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";

export default function DemoPlayer(props: IDemoPlayer) {
    const {open, onVideoClose} = {...props}
    return (
        <Modal open={open}
               onClose={() => onVideoClose(false)}>
            <div className={'demo-video-player-wrapper'}>
                <ModalControl onClose={() => onVideoClose(false)}/>
                <VideoPlayer/>
            </div>
        </Modal>
    )
}


function ModalControl(props: IModalControl) {
    const {onClose} = {...props}

    return (
        <div className={'demo-video-player-control'} onClick={onClose}>
            <IconButton className={'player-close-button'}
                        onClick={onClose}
                        size={'small'}>
                <CloseRounded
                    style={{height: 20, width: 20, background: 'rgb(238, 238, 238)', borderRadius: '50%'}}
                />
            </IconButton>
            <p className={'close-button-text'}>
                Close
            </p>
        </div>
    )
}


interface IDemoPlayer {
    open: boolean,
    onVideoClose: (e?: any) => any
}

interface IModalControl {
    onClose: (e?: any) => any
}