import { memo } from "react";
import { Alert, Typography } from "@mui/material";

import { apiErrorProgress } from "../../helper";
import { SMainMenu } from "../Skeletons";
import MainMenu from "./MainMenu";

import './index.scss'

export default memo(function MenuGroup(props: IMenuGroup) {
    const { progress, data } = { ...props }
    const { isSuccess, isError, isLoading, error } = progress

    return (
        <div>
            <ul style={{ width: "100%", height: "100%" }}>
                {isLoading && <SMainMenu />}
                {
                    isSuccess && data.map((table: ISalesforceObjectList) => <MainMenu
                        key={table.name} {...table} />)
                }
                {
                    !isError && data.length < 1 && <Typography color={'textSecondary'} variant={'body2'}>Oops! No data</Typography>
                }
            </ul>
            {
                isError &&
                <Alert severity={'error'} sx={{ borderRadius: 2, my: 1, mb: 1.5 }}>
                    {apiErrorProgress(error)}
                </Alert>
            }
        </div>
    )
}
)

interface IMenuGroup {
    progress: any,
    data: any
}

interface ISalesforceObjectList {
    deprecatedAndHidden: boolean,
    label: string | number,
    labelPlural: string | number,
    name: string | number,
    custom: string | number
}