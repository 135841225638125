import { ArrowCircleDownRounded, ArrowCircleUpRounded, HighlightOffRounded } from '@mui/icons-material'

const transferMethods = [
    {
        icon: ArrowCircleDownRounded,
        apiName: 'import',
        name: 'Import'
    },
    {
        icon: ArrowCircleUpRounded,
        apiName: 'export',
        name: 'Export'
    },
    {
        icon: HighlightOffRounded,
        apiName: 'delete',
        name: 'Delete'
    }
]

export default transferMethods