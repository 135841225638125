import { Button, Paper } from "../../shared";
import { Typography } from "@mui/material";
import { TransferWithinAStationRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function ShifterNewsCard() {
    return (
        <Paper
            sx={{ p: 2 }}
            style={{ background: 'linear-gradient(140deg, #e9af0042 -17%, white 33%)' }}
        >
            <Typography variant={'h6'}
                color={'text.primary'}
                gutterBottom>
                DataJunction
            </Typography>
            <Typography
                component={'div'}
                variant={'body1'}
                fontSize={'.90rem'}
                gutterBottom>
                A user-friendly and efficient data migration tool designed to move and transform data seamlessly.
            </Typography>
            <Button
                sx={{ mt: 1 }}
                variant={'outlined'}
                color={'inherit'}
                size={'small'}
                startIcon={<TransferWithinAStationRounded />}>
                <Link to={'/datajunction'} style={{ textDecoration: 'none', color: 'inherit' }}>
                    MOVE DATA
                </Link>
            </Button>
        </Paper>
    )
}