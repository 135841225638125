import { Typography, Box, IconButton, Grid } from "@mui/material"
import { GitHub, OpenInNew } from "@mui/icons-material"

import { Paper } from "../../shared"
import { Link } from "react-router-dom"
const fontProperty = { fontWeight: 600, fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)' }


const projects = [
    {
        id: 1,
        name: 'aws-throttle-fixer',
        description: `aws-throttle-fixer is a Node.js package that works in conjunction with the
        AWS SDK to address throttling issues that can arise when making API calls to AWS services.
        With this package, developers can make a large number of API calls without worrying about throttling,
        and can ensure their code runs efficiently and reliably.`,
        technologies: ['JavaScript', 'NodeJs', 'npm', 'aws-sdk'],
        githubLink: 'https://github.com/nithinpachday/aws-throttle-fixer',
        externalLink: 'https://www.npmjs.com/package/aws-throttle-fixer'
    },
    {
        id: 2,
        name: 'terraform-json-converter',
        description: `terraform-json-converter is an npm package that allows developers to easily convert Terraform code into JSON format. 
        With this package, developers can quickly and easily convert their Terraform code into JSON, 
        making it easier to manipulate and work with the data in other applications or scripts.`,
        technologies: ['JavaScript', 'NodeJs', 'npm', 'hcl'],
        githubLink: 'https://github.com/nithinpachday/terraform-json-converter',
        externalLink: 'https://www.npmjs.com/package/terraform-json-converter'
    },
    {
        id: 3,
        name: 'ForceQuery',
        description: `ForceQuery is a sub-product of nithinpb.com that provides advanced query capabilities 
        to easily query Salesforce data using either SOQL or SOSL without writing any code.
         It allows users to create complex queries using a drag-and-drop interface and provides intelligent suggestions 
         to ensure that queries are optimized for performance. 
         ForceQuery is designed to be user-friendly and efficient, 
         making it easier for users to extract the data they need from Salesforce.`,
        technologies: ['JavaScript', 'NodeJs', 'npm', 'React'],
        githubLink: '',
        externalLink: 'http://nithinpb.com/dashboard'
    },
    {
        id: 4,
        name: 'PaperBlog',
        description: `Paperblog is a blogging platform that allows users to publish and share their written content with a wider audience. 
        With Paperblog, users can create and customize their own blogs, and publish articles on a wide range of topics such as technology, 
        travel, lifestyle, and more. 
        The website was built using modern web technologies such as HTML, CSS, JavaScript, and PHP, 
        and features a user-friendly interface and responsive design to ensure an optimal viewing experience on all devices.`,
        technologies: ['JavaScript', 'WordPress', 'PHP', 'apache'],
        githubLink: '',
        externalLink: 'https://www.paperblog.gamliot.com/'
    },
]

export function MyBuilds() {
    return (
        <Box sx={{ minHeight: '50vh', maxWidth: 1200, mb: 6 }}>
            <Typography
                variant={'inherit'}
                component={'span'}
                color={'primary'}
                sx={fontProperty}
                className={'colored-text'}>
                Some Highlights
            </Typography>
            <Typography variant={'body2'} color={'text.secondary'} sx={{ mt: '-5px' }}>
                What i was building?
            </Typography>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={5}>
                    {
                        projects.map((e: any) => <Project {...e} key={e.id} />)
                    }
                </Grid>
            </Box>
        </Box>
    )
}



function Project(props: any) {
    //@ts-ignore
    const { name, description, githubLink, externalLink, technologies } = { ...props }
    return (
        <Grid item xs={12} md={6} lg={6}>
            <Paper
                sx={{ p: 3, mt: 2, }}
                style={{ height: '100%', position: 'relative' }}
                className={'me-projects-card'}>
                <Box display={'flex'} alignItems={'flex-start'} mb={2}>
                    <Box flex={1}>
                        <Typography
                            variant={'body2'}
                            color={'text.primary'}>
                            Featured Project
                        </Typography>
                        <Typography
                            style={{ fontSize: 'clamp(1rem, 1.1857rem, 1.25rem)', }}
                            sx={{ fontWeight: 700 }}
                            variant={'h5'}
                            color={'text.primary'}>
                            {name}
                        </Typography>
                    </Box>
                    <Box display={'flex'} gap={1} mt={-1}>
                        {
                            githubLink && <IconButton href={githubLink} target={'_blank'}>
                                <GitHub />
                            </IconButton>
                        }
                        <IconButton href={externalLink} target={'_blank'}>
                            <OpenInNew />
                        </IconButton>
                    </Box>
                </Box>
                <Typography>
                    {description}
                </Typography>

                <Box height={'16px'}>

                </Box>
                <Box display={'flex'} gap={3} mt={2.5} position={'absolute'} bottom={16}>
                    {
                        technologies.map((e: string) => <Typography variant="body2" color={'text.secondary'}>{e}</Typography>)
                    }
                </Box>

            </Paper >
        </Grid>
    )
}