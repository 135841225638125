import React from "react";
import {Grow, Popper as MuiPopper} from '@mui/material'
import {PopperProps} from "../../interfaces";

export default function Popper(props: PopperProps) {
    const {open, anchorEl, placement = 'bottom', children} = {...props}
    return (
        <MuiPopper
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            style={{zIndex: 1000, maxHeight: '100vh'}}
            transition
        >
            {({TransitionProps}) => (
                <Grow {...TransitionProps} timeout={350} style={{ transformOrigin: '0 0 0' }}>
                    <div>
                        {children}
                    </div>
                </Grow>
            )}
        </MuiPopper>
    )
}