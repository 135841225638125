import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'

import { Header, Footer } from './components';
import { MainRoute } from "./routes";
import GlobalProvider from "./providers";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 5 * 60 * 1000,
        },
    },
})

function App() {

    return (
        <div className="ForceQuery-container">
            <QueryClientProvider client={queryClient}>
                <Router>
                    <GlobalProvider>
                        {/* <Header /> */}
                        <MainRoute />
                        {/* <Footer /> */}
                    </GlobalProvider>
                </Router>
            </QueryClientProvider>
        </div>
    );
}

export default App;
