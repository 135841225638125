import React from 'react';
import {Grid, Typography} from "@mui/material";
import {Paper} from "../../shared";
import {Link} from "react-router-dom";
import {KeyboardArrowRightRounded} from '@mui/icons-material'

export default function StartUsingNow() {
    const fontProperty = {fontWeight: 600, fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)'}
    return (
        <div className={'width-max-adjust start-using-now'}>
            <Grid container justifyContent={'space-between'} spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography gutterBottom color={'primary'} variant={'subtitle2'}>Start using data!</Typography>
                    <Typography variant={'h4'} component={'div'} sx={fontProperty}>
                        Start using <br/>
                        <Typography variant={'inherit'}
                                    className={'colored-text'}
                                    component={'span'}
                                    color={'primary'}
                                    sx={fontProperty}>
                            nithinpb.com&#160;
                        </Typography>
                        today!
                    </Typography>
                    <Link to={'/dashboard'}>
                        <button className={'getting-started'} style={{marginTop: 16}}>
                            <Typography variant='button'>
                                Get started
                            </Typography>
                            <KeyboardArrowRightRounded sx={{mt: -0.2}}/>
                        </button>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid spacing={2} container>
                        {
                            cards.map(card => <Card {...card} key={card.id}/>)
                        }
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

const cards = [
    {
        id: 1,
        title: 'Blogs',
        description: 'Read latest information about nithinpb.com',
        link: 'https://www.paperblog.gamliot.com/category/force-query/'
    },
    {
        id: 2,
        title: 'Something in mind?',
        description: 'Found some bugs or need a new Feature?'
    }
]


interface CardProps {
    link?: string,
    title: string,
    description: string
}

function Card(props: CardProps) {
    const {link = '', title, description} = {...props}
    const fontProperty = {fontWeight: 600}
    return (
        <Grid item xs={12} lg={6}>
            <Paper className={'start-now-card'}>
                <Typography gutterBottom
                            color={'textPrimary'}
                            sx={fontProperty}
                            variant={'subtitle1'}>
                    {title}
                </Typography>
                <Typography gutterBottom
                            color={'textSecondary'}
                            variant={'body1'}>
                    {description}
                </Typography>
                <a href={link}>
                    <Typography color={'primary'}
                                sx={fontProperty}
                                variant={'subtitle2'}>
                        Read more
                    </Typography>
                    <KeyboardArrowRightRounded/>
                </a>
            </Paper>
        </Grid>
    )
}