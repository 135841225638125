import Welcome from './Welcome'
import './index.scss'

export default function Shifter() {

    return (
        <div className={'shifter-wrapper'}>
            <Welcome />
        </div>
    )
}
