import {useEffect, useState} from "react";
import {CalendarViewMonthRounded, CloseRounded, CodeRounded, ImportExportRounded} from "@mui/icons-material";
import {Box, Tab, Tabs, Typography, IconButton, Divider, Stack} from "@mui/material";
import {ISoslQueryResult, IObjectListTab, ISoslItemTab} from "../../interfaces"
import {DataGrid} from "..";
import {Button, Modal} from "../../shared";
import './index.scss'

export default function SoslQueryResult(props: ISoslQueryResult) {
    const {onClose, queryResults = {searchResult: [], resultDistribution: {}, totalCount: 0}, open} = {...props}
    const [activeTab, setActiveTab] = useState(0);
    const [objectList, setObjectList] = useState<ISoslItemTab[]>([])
    const [gridData, setGridData] = useState<any[]>([])

    const outerBoxStyleProperties = {
        display: 'flex',
        height: '100%'
    }


    useEffect(() => {
        if (open) {
            const result = Object.entries(queryResults.resultDistribution).map((e: any) => {
                return ({
                    objectName: e[0],
                    count: e[1]
                })
            })
            setObjectList(result)
            setGridData(queryResults.searchResult)
            setActiveTab(0)
        }
    }, [open])


    useEffect(() => {
        if (activeTab === 0) setGridData(queryResults.searchResult)
        else setGridData(queryResults.searchResult.filter(({object}) => object === objectList[activeTab - 1]['objectName']))

    }, [activeTab])

    return (
        <Modal open={open} onClose={() => {
        }} width={'80%'}>
            <div className={'sosl-query-result-container'}>
                <ModalControl onClose={onClose}/>
                <Box sx={outerBoxStyleProperties} className={'sosl-query-result-wrapper'}>
                    <ObjectListTab
                        activeTab={activeTab}
                        onTabChange={setActiveTab}
                        totalCount={queryResults.totalCount}
                        objectList={objectList}
                    />
                    <QueryResultDataGrid
                        gridData={gridData}/>
                </Box>
                <Divider/>
                <Stack spacing={1} direction={'row'} sx={{px: 2, py: 1.5}} justifyContent={'end'}>
                    <Button
                        size={'small'}
                        variant={'contained'}
                        startIcon={<CodeRounded/>}
                        disabled>
                        SCRIPT
                    </Button>
                    <Button
                        size={'small'}
                        variant={'contained'}
                        startIcon={<ImportExportRounded/>}
                        disabled>
                        Dump using Shifter
                    </Button>
                </Stack>
            </div>
        </Modal>
    )
}


function ModalControl(props: any) {
    const {onClose}: { onClose: any } = {...props}
    return (
        <div className={'sosl-query-result-modal-action'}>
            <Typography>
                SOSL Result - {new Date().toDateString()}
            </Typography>
            <IconButton size={'small'} onClick={onClose} style={{flex: 0}}>
                <CloseRounded/>
            </IconButton>
        </div>
    )
}


function ObjectListTab(props: IObjectListTab) {
    const {objectList, activeTab, onTabChange, totalCount} = {...props}
    return (
        <Tabs
            orientation="vertical"
            variant="scrollable"
            onChange={(e, value) => onTabChange(value)}
            value={activeTab}
            sx={{borderRight: 1, borderColor: 'divider', height: '100%', minWidth: 200}}
        >
            <Tab
                label={`All (${totalCount ? totalCount : 0})`}
                disableRipple
                icon={
                    <CalendarViewMonthRounded
                        fontSize={'small'}
                    />
                }
                iconPosition={'start'}
                style={{minHeight: 40, justifyContent: 'start', textTransform: 'none'}}/>
            {
                objectList.map((e: ISoslItemTab, index) => <Tab
                    label={`${e.objectName}(${e.count})`}
                    key={e.objectName}
                    disableRipple
                    icon={
                        <CalendarViewMonthRounded
                            fontSize={'small'}
                        />
                    }
                    iconPosition={'start'}
                    style={{minHeight: 40, justifyContent: 'start', textTransform: 'none'}}/>)
            }
        </Tabs>
    )
}


function QueryResultDataGrid({gridData = []}: { gridData: any[] }) {
    return (
        <div className={'sosl-result-grid-wrapper'}>
            <DataGrid gridData={gridData}/>
        </div>
    )
}