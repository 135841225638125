import { Editor } from ".."

export default function RowEditor(props: IRowEditor) {
    const { value, onValueChange } = { ...props }

    return (
        <div className={'dashboard-editor-visual-container'}>
            <div className={'dashboard-query-wrapper'}>
                <div className={'dashboard-query-editor-container'}>
                    <Editor
                        readOnly={false}
                        value={value}
                        onValueChange={onValueChange}
                    />
                </div>
            </div>
        </div>
    )
}


interface IRowEditor {
    value: string,
    onValueChange: (e: string) => any
}