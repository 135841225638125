import { Typography, Box, Grid, Tabs, Tab } from "@mui/material"
import { Navigation } from '@mui/icons-material'

import { WorkExperience } from './WorkExperence'
const fontProperty = { fontWeight: 600, fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)' }

const myTech = [
    "React", "Nodejs", "AWS", "Terraform", "JavaScript", ".NET"
]
export function AboutMe() {
    return (
        <div>
            <Box sx={{ minHeight: '50vh' }}>

                <Typography
                    variant={'inherit'}
                    component={'span'}
                    color={'primary'}
                    sx={fontProperty}
                    className={'colored-text'}>
                    About Me
                </Typography>
                <Typography variant={'body2'} color={'text.secondary'} sx={{ mt: '-5px' }}>
                    Who i am I? what i am doing?
                </Typography>
                <Typography sx={{ maxWidth: 700, mt: 2 }}>
                    Hello, I'm Nithin PB - a detail-oriented software engineer with a keen eye for crafting exceptional digital experiences.
                    I bring over {new Date().getFullYear() - 2019} years of experience in front-end, back-end, and cloud development,
                    and have a proven track record of delivering cutting-edge solutions. Whether it's creating responsive websites,
                    designing user-friendly interfaces, or optimizing complex back-end systems,
                    I'm always up for a challenge. When I'm not immersed in code, I love exploring the great outdoors,
                    trying out new cuisines, or reading up on the latest tech trends.
                </Typography>
                <Typography sx={{ maxWidth: 700, mt: 2 }}>
                    Here are a few technologies I’ve been working with recently
                </Typography>

                <Grid container sx={{ maxWidth: 300, mt: 3, mb: 3 }}>
                    {myTech.map((e: string) => (
                        <Grid item xs={6} key={e} >
                            <Box display={'flex'} alignItems={'center'} gap={0.5}>
                                <Navigation sx={{ fontSize: 'small', transform: 'rotate(90deg)' }} color={'primary'} />
                                <Typography sx={{ maxWidth: 700 }}> {e} </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <WorkExperience />
        </div >
    )
}