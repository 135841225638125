import { Paper } from "../../shared";
import { Typography } from "@mui/material";
import { KeyboardArrowRightRounded } from "@mui/icons-material";
import { ITransferMethod } from "../../interfaces";
import './index.scss'

export default function TransferMethod(props: ITransferMethod) {
    const { icon: Icon, name, onClick, apiName } = { ...props }

    const handleOnClick = () => {
        onClick(apiName)
    }

    return (
        <div className={'transfer-method-container'} onClick={handleOnClick}>
            <Paper variant={'outlined'} className={'transfer-method-wrapper'}>
                <Icon className={'tm-icon'}/>
                <Typography variant={'h6'} className={'tm-p-n'}>
                    {name}
                </Typography>
                <KeyboardArrowRightRounded className="tm-icon" />
            </Paper>
        </div>
    )
}