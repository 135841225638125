import React, {createContext} from "react";


const SalesforceInstanceContext: React.Context<ISalesforceInstanceContext> = createContext<ISalesforceInstanceContext>({
    salesforceInstance: '',
    setSalesforceInstance: () => {
    }
});
export default SalesforceInstanceContext;


interface ISalesforceInstanceContext {
    salesforceInstance: string,
    setSalesforceInstance: (e: any) => any
}