import React, {createContext} from "react";
import {IQueryTabObject} from "../../interfaces";

const QueryTabContext: React.Context<ISavedQueriesContext> = createContext<ISavedQueriesContext>({
    tabQueries: [],
    setTabQueries: () => {
    }
});
export default QueryTabContext;


interface ISavedQueriesContext {
    tabQueries: IQueryTabObject[],
    setTabQueries: (e: any) => any
}

