import React from "react";
import { Link, NavLink } from 'react-router-dom';
import { Stack, Typography } from "@mui/material";
import { Button } from '../../shared'
import { routes } from "../../constants/routes";
import Logo from "../../assets/images/logo_v2.svg";

export default function NotepadHeader(props: any) {
    const { notepadId, warning }: { notepadId: string, warning: boolean } = { ...props }

    return (
        <header style={{ width: '100%', height: 56 }}>
            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}
                className="header-wrapper" >
                <nav>
                    <NavLink to="/notedrop" style={{ display: 'flex', padding: '13px 5px' }}>
                        <img style={{ width: 68 }} draggable={true} src={Logo} alt="nithinpb.com logo" />
                    </NavLink>
                    {
                        notepadId &&
                        <div style={{ display: 'flex', padding: '16px 16px', background: warning ? 'red' : '#f0a538', color: '#fff', transition: '0.3s' }} className={warning ? "blink_me" : ""}>
                            <Typography>
                                {notepadId}
                            </Typography>
                        </div>
                    }
                </nav>
                <div>
                    <Login />
                </div>
            </Stack>
        </header>
    )
}


function Login() {
    return (
        <Button>
            <Link
                style={{ textDecoration: 'none', color: "inherit" }}
                to={routes.SALESFORCE_AUTH}>
                Login
            </Link>
        </Button>
    )
}