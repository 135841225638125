import {Fade, Stack, Typography} from "@mui/material";
import {TableRowsOutlined} from '@mui/icons-material'
import {ISubMenu, IPropertiesSingleItem} from "../../interfaces";

export default function SubMenu(props: ISubMenu) {
    const {properties, onHover} = {...props}

    const dragStart = (e: any, apiName: string | number) => {
        e.dataTransfer.setData('text', `${apiName}`);
    }

    return (
        <div onMouseEnter={onHover}>
            <Fade in={true}>
                <ul>
                    {
                        properties.map((column: IPropertiesSingleItem) => <li key={column.name}
                                                                              draggable
                                                                              onDragStart={(e) => dragStart(e, column.name)}>
                            <Stack direction={'row'} spacing={1.2} alignItems={'start'}>
                                <TableRowsOutlined color={'action'} fontSize={'small'}
                                                   style={{color: column?.referenceTo?.length > 0 ? '#D98F02FF' : ''}}/>
                                <Typography variant={'body2'}>
                                    {column.label}
                                </Typography>
                            </Stack>
                        </li>)
                    }
                </ul>
            </Fade>
        </div>
    )
}

