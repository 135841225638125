import { memo } from "react";
import { Divider, Stack } from "@mui/material";
import { ShifterNewsCard } from "../";
import './index.scss'

export default memo(function NewsFeed() {
    return (
        <div className={'fq-news-feed'}>
            <Stack spacing={2}>
                <ShifterNewsCard />
            </Stack>
        </div>
    )
})