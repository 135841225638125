import { Grid } from "@mui/material"
import { StorageOption } from ".."
import { storageProvider } from "../../constants"

export default function StorageMethods(props: IStorageMethods) {
    const { onProviderChange } = { ...props }
    
    const handleOptionChange = (e: string) => {
        onProviderChange(e)
    }

    return (
        <Grid container spacing={2}>
            {
                storageProvider.map(e =>
                    <Grid item xs={12} key={e.storageProviderName}>
                        <StorageOption  {...e} onClick={handleOptionChange} />
                    </Grid>)
            }
        </Grid>
    )
}

interface IStorageMethods {
    onProviderChange: (e?: any) => any
}
