import { useQuery } from 'react-query'
import { useResponseParser, useAxios } from '..'
import { apiEndPoint } from '../../constants/apiEndPoints'

export default function useSalesforceObject() {
    const { axiosPrivate } = useAxios()
    const parseResponse = useResponseParser()

    const useSalesforceObjects = () => useQuery(['objects'],
        () => axiosPrivate.get(apiEndPoint.SALESFORCE_LIST_OBJECTS).then(parseResponse))

    const useSalesforceSingleObject = (objectName: string | number) => useQuery(['object', objectName],
        () => axiosPrivate
            .get(apiEndPoint.SALESFORCE_DESCRIBE_OBJECT, { params: { objectName } })
            .then(parseResponse),
        {
            refetchOnWindowFocus: false,
            enabled: false
        })

    return { useSalesforceSingleObject, useSalesforceObjects }
}