import { Typography, Box } from '@mui/material'
import { SecurityOutlined } from '@mui/icons-material'
import { FeatureCardProps } from "../../interfaces";
import './index.scss'


export default function FeatureCard(props: FeatureCardProps) {
    const { icon, title, description } = { ...props }
    return (
        <div className={'feature-card'}>
            <Box display={'flex'} alignItems={'center'} gap={1} sx={{ pb: 1 }}>
                {icon ? icon : <SecurityOutlined fontSize={"small"} />}
                <Typography gutterBottom
                    sx={{ pt: 1, fontWeight: 600, }}
                    color={'text.primary'}>
                    {title}
                </Typography>
            </Box>


            <Typography color={'text.primary'} align={'left'}>
                {description}
            </Typography>
        </div>
    )
}