import { Box, Typography, Stack } from "@mui/material"
import { MailOutlineOutlined } from '@mui/icons-material'

const fontProperty = { fontWeight: 600, fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)' }

export function GetInTouch() {
    return (
        <Box sx={{ maxWidth: 700, mb: 5, mt: 10 }}>
            <Typography
                variant={'inherit'}
                color={'primary'}
                sx={fontProperty}
                className={'colored-text'}>
                Lets Connect !
            </Typography>
            <Typography sx={{ mt: 2 }}>
                Although I’m not currently looking for any new opportunities,
                my inbox is always open. Whether you have a question or just want to say hi,
                I’ll try my best to get back to you!
            </Typography>

            <Box sx={{ mt: 2 }}>
                <a href="mailto:nithinpb.com@gmail.com">
                    <button className={'getting-started view-demo'}>
                        <Stack direction="row" alignItems={'center'} spacing={1}>
                            <MailOutlineOutlined fontSize={'medium'} />
                            <Typography variant='button' sx={{ textTransform: 'none' }}>
                                Drop a mail
                            </Typography>
                        </Stack>
                    </button>
                </a>
            </Box>
        </Box>
    )
}