export { default as workFlow } from './home'
export { default as storageProvider } from './storageProvider'
export { default as transferMethods } from './transferMethods'
export { default as comparisonOperator } from './comparisonOperators'
export * from './meta'
export * from './footer'


export const fallbackQuery = `SELECT Id, Name, CreatedDate FROM Contact
WHERE LastName LIKE 'A%' AND CreatedDate > YESTERDAY AND Account.Name = 'nithinpb.com'
ORDER BY CreatedDate DESC, Name ASC
LIMIT 5 OFFSET 10`