import { memo, useEffect, useState } from "react";
import { Divider, Stack } from "@mui/material";
import { useSalesforceInstance, useTabQueries, useBuildVisualQuery } from "../../hooks";
import Split from "react-split";
import {
    DataGrid,
    Editor,
    ErrorAlert,
    QueryControl,
    QueryTab,
    SaveQuery,
    SoslQueryResult,
    VisualQueryBuilder,
    RowEditor,
    QueryOperationSelector
} from "..";
import {
    handleActiveTabChange,
    handleExecuteQuery,
    handleNewQueryAdd,
    handleRemoveQuery,
    handleSetEditorValue,
    getQueryString,
    initialDataLoad,
    buildQueryWithLimitProperties,
    handleQueryTypeChange,
    getActiveQueryType, handleQueryFormat, getEditorMode, handleEditorModeChange
} from "./utils";
import { ConditionBlock } from "../VisualQueryComposer";

export function QueryWindow() {
    const [activeTab, setActiveTab] = useState(1)
    const [apiProgress, setApiProgress] = useState({ loading: false, ready: null, error: false })
    const [queryResults, setQueryResults] = useState<any>({ records: null, totalSize: 0 })
    const [saveDialogControl, setSaveDialogControl] = useState(false)
    const [limitString, setLimitString] = useState<string | number>(100)
    const [limitEnabled, setLimitEnabled] = useState<boolean>(true)
    const [soslResultOpen, setSoslResultOpen] = useState(false);
    const [visualQuery, setVisualQuery] = useState({
        object: '',
        fields: [],
        orderBy: {
            field: null,
            direction: 'ASC',
            nullPosition: 'FIRST'
        },
        offset: 0,
        limit: 100
    })
    const { tabQueries, setTabQueries } = useTabQueries()
    const { salesforceInstance } = useSalesforceInstance()
    const buildQueryFromVisualState = useBuildVisualQuery()

    const handleQueryExecutionLoc = () => {
        handleExecuteQuery(
            buildQueryWithLimitProperties(limitEnabled, limitString, activeTab, tabQueries),
            setApiProgress,
            setQueryResults,
            salesforceInstance,
            getActiveQueryType(activeTab, tabQueries)).then()
    }

    const handleSaveDialogClose = () => {
        setSaveDialogControl(false)
    }



    useEffect(() => {
        const newQuery = buildQueryFromVisualState(visualQuery)
        handleSetEditorValue(activeTab, newQuery, tabQueries, setTabQueries)
    }, [visualQuery])

    useEffect(() => {
        if (queryResults.resultDistribution) {
            setSoslResultOpen(true)
        }
    }, [queryResults])

    useEffect(() => {
        initialDataLoad(setQueryResults)
    }, [])

    return (
        <div className={'dashboard-content'}>
            <SaveQuery
                open={saveDialogControl}
                queryType={getActiveQueryType(activeTab, tabQueries) ? 'SOSL' : 'SOQL'}
                queryString={getQueryString(activeTab, tabQueries)}
                onClose={handleSaveDialogClose}
            />
            <SoslQueryResult
                onClose={() => setSoslResultOpen(false)}
                open={soslResultOpen}
                queryResults={queryResults}
            />
            <Stack direction={'row'} flexBasis={'1'}>
                <Split
                    minSize={200}
                    gutterSize={2}
                    sizes={[75, 25]}
                    className={'query-box split'}
                    direction="vertical"
                >
                    <div className={'query-editor-controls'}>
                        <QueryTab
                            editorMode={getEditorMode(activeTab, tabQueries)}
                            onEditorModeChange={(value: string) => handleEditorModeChange(activeTab, value, setTabQueries)}
                            activeTab={activeTab}
                            queryList={tabQueries}
                            onNewQuery={() => handleNewQueryAdd(tabQueries, setTabQueries, setActiveTab)}
                            onDelete={(id: number) => handleRemoveQuery(id, tabQueries, setTabQueries, setActiveTab)}
                            onClick={(e: any, value: any) => handleActiveTabChange(e, value, setActiveTab)}
                        />
                        <div className={'dashboard-editor-container'}>
                            {
                                getEditorMode(activeTab, tabQueries) === 'VISUAL' ?
                                    <VisualQueryBuilder
                                        value={getQueryString(activeTab, tabQueries)}
                                        onValueChange={(value: string) => handleSetEditorValue(activeTab, value, tabQueries, setTabQueries)}
                                        state={visualQuery}
                                        setState={setVisualQuery}
                                    /> :
                                    <RowEditor
                                        value={getQueryString(activeTab, tabQueries)}
                                        onValueChange={(value: string) => handleSetEditorValue(activeTab, value, tabQueries, setTabQueries)}
                                    />
                            }
                            <QueryOperationSelector
                                disabled={apiProgress.loading}
                                active={getActiveQueryType(activeTab, tabQueries)}
                                onFormat={() => handleQueryFormat(activeTab, setTabQueries)}
                                onSelectionChange={(sosl: boolean) => handleQueryTypeChange(activeTab, setTabQueries, sosl)}
                            />
                        </div>
                        <ErrorAlert open={Boolean(apiProgress.error)} errorMessage={apiProgress.error} />
                        <QueryControl
                            limitText={limitString}
                            limitState={limitEnabled}
                            executionStatus={apiProgress.ready}
                            totalRows={queryResults?.totalSize || 0}
                            loading={apiProgress.loading}
                            onLimitChange={setLimitString}
                            onLimitStateChange={setLimitEnabled}
                            onSave={() => setSaveDialogControl(true)}
                            onQueryExecution={handleQueryExecutionLoc}
                        />
                    </div>
                    <div className={'data-grid-wrapper'}>
                        <DataGrid
                            gridData={queryResults?.records || []}
                        />
                    </div>
                </Split>
            </Stack>
        </div>
    )
}

export default memo(QueryWindow)