import {
    SecurityOutlined,
    ConstructionOutlined,
    AutoAwesomeOutlined,
    AccountTreeOutlined,
    BrushOutlined,
    FlashOnOutlined
} from '@mui/icons-material'

//@ts-ignore
import cloudRepo from '../assets/icons/cloud-repo.gif'
import editIcon from '../assets/icons/edit.gif'
import loadIcon from '../assets/icons/load.gif'
import computerIcon from '../assets/icons/computer.gif'
import documentIcon from '../assets/icons/document.gif'
import clockIcon from '../assets/icons/clock.gif'
import toolIcon from '../assets/icons/tool.gif'
import targetIcon from '../assets/icons/target.gif'

export const workflow = [
    {
        pointerIndex: 1,
        smallDescription: 'Login to the Salesforce instance using OAuth2 or Username/Password'
    },
    {
        pointerIndex: 2,
        smallDescription: 'Write SOQL/SOSL in the intelligent query editor. Make sure to use tabs and Save option :) '
    },
    {
        pointerIndex: 3,
        smallDescription: 'Hit EXECUTE button and wait for it! In few seconds results will be displayed '
    },
    {
        pointerIndex: 4,
        smallDescription: 'Look into the data, do the processing save the result/query if you want it in the future'
    }
]

export const features = [
    {
        //icon: <SecurityOutlined fontSize={'medium'} style={{ color: '#21c416' }} />,
        icon: <IconImage source={targetIcon} />,
        title: 'Salesforce data query',
        description: "nithinpb.com allows users to query Salesforce data using either SOQL or SOSL. The tool's advanced query capabilities provide a granular view of your data, making it easy to filter and sort records based on specific criteria"
    },
    {
        //icon: <BrushOutlined fontSize={'medium'} style={{ color: '#e54949' }} />,
        icon: <IconImage source={editIcon} />,
        title: 'Visual Query Editor',
        description: 'Visual editor that simplifies the query creation process and allows users run queries easily without writing any code. The drag-and-drop interface and intelligent suggestions make it easy to select the fields and conditions required for the query.'
    },
    {
        //icon: <FlashOnOutlined fontSize={'medium'} style={{ color: '#e89624' }} />,
        icon: <IconImage source={loadIcon} />,
        title: 'Data migration',
        description: 'nithinpb.com facilitates the migration of Salesforce data to other systems or platforms. nithinpb.com offers a simple and user-friendly interface that enables users to map fields and migrate data effortlessly.'
    },
    {
        //icon: <AccountTreeOutlined fontSize={'medium'} style={{ color: '#e531a6' }} />,
        icon: <IconImage source={documentIcon} />,
        title: 'Data transformation',
        description: "Users can transform their Salesforce data to fit the requirements of the target system or platform using mapping and transformation features.The tool allows users create complex field transformations to ensure data consistency."
    },
    {
        //icon: <AutoAwesomeOutlined fontSize={'medium'} style={{ color: '#316ae5' }} />,
        icon: <IconImage source={clockIcon} />,
        title: 'Schedule automation',
        description: 'nithinpb.com allows users to schedule data queries and migrations, making it easier to automate routine tasks. Users can set up schedules based on specific time zones and frequencies to ensure that their data is up-to-date at all times.'
    },
    {
        //icon: <ConstructionOutlined fontSize={'medium'} style={{ color: '#5d3be2' }} />,
        icon: <IconImage source={toolIcon} />,
        title: 'Backup and restore',
        description: "Users can backup their Salesforce data and restore it in case of any data loss or corruption. nithinpb.com's backup and restore capabilities help ensure data availability and continuity, even in the face of unexpected events."
    },
]

function IconImage(props: any) {
    const { source = '' }: { source: any } = { ...props }
    return (
        <img src={source} style={{ height: 28, boxShadow: 'none' }} />
    )
}

export default workflow