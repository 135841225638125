import React, {useState} from "react";
import {Box, Checkbox, CircularProgress, Stack, Typography} from "@mui/material";
import {PlayArrowRounded, SaveOutlined} from "@mui/icons-material";
import {Button} from "../../shared";
import {ExecutionStatus} from "../";
import Menu from "../../shared/Menu";
import {IQueryControl} from "../../interfaces";
import './index.scss'

export default function QueryControl(props: IQueryControl) {
    const {
        totalRows,
        limitText = 100,
        limitState,
        executionStatus = null,
        loading = false,
        onSave,
        onQueryExecution,
        onLimitChange,
        onLimitStateChange
    } = {...props}
    const [anchorEl, setAnchorEl] = useState(null);
    const limitOptions = [50, 100, 500, 1000, 5000, 10000, 100000, 'All']
    return (
        <Box className={'query-control-container'} display={'flex'} justifyContent={'space-between'}
             alignItems={'center'}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Button variant={'contained'}
                        onClick={onQueryExecution}
                        size={'small'}
                        sx={{width: 100, borderRadius: 1.5}}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={16} color={'primary'}/> : <PlayArrowRounded/>}
                        disableElevation>
                    EXECUTE
                </Button>
                <Button variant={'text'}
                        onClick={onSave}
                        size={'small'}
                        startIcon={<SaveOutlined/>}>
                    SAVE
                </Button>
                <Stack direction={'row'} alignItems={'center'}>
                    <Checkbox onChange={(e) => onLimitStateChange(e.target.checked)}
                              checked={limitState}
                              sx={{ml: -1}}
                              size={'small'}/>
                    <Menu anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                          menuList={limitOptions}
                          onChange={(e) => onLimitChange(e)}
                    />
                    <div onClick={(e: any) => setAnchorEl(e.currentTarget)} style={{cursor: 'pointer'}}>
                        <Typography color={'textSecondary'} variant={'body2'}>{`Limit to ${limitText} Rows`}</Typography>
                    </div>
                </Stack>
            </Stack>
            <ExecutionStatus loading={loading} executionStatus={executionStatus} totalRows={totalRows}/>
        </Box>
    )
}