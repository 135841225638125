import { Collapse, IconButton, Stack, Badge } from "@mui/material";
import { TransitionGroup } from 'react-transition-group';
import { IQueryTab, IQueryTabObject, ITabSingleItem } from "../../interfaces";
import NewQueryButton from "../AddNewButton";
import { CloseOutlined } from "@mui/icons-material";
import { QueryWindowSelector } from "..";
import './index.scss'

export default function QueryTab(props: IQueryTab) {
    const { onNewQuery, editorMode, onEditorModeChange } = { ...props }
    return (
        <div className={'query-tab-wrapper'}>
            <div className={'query-tab-container'}>
                <NewQueryButton onClick={onNewQuery} />
                <TabList {...props} />
            </div>
            <QueryWindowSelector editorMode={editorMode} onEditorModeChange={onEditorModeChange} />
        </div>
    )
}


function TabList(props: IQueryTab) {
    const { activeTab, queryList, onClick, onDelete } = { ...props }
    return (
        <ul>
            <TransitionGroup>
                {queryList.map(({ id, queryType }: IQueryTabObject) => (
                    <Collapse key={id}>
                        <TabSingleItem
                            key={id}
                            id={id}
                            queryType={queryType}
                            onDelete={onDelete}
                            activeTab={id === activeTab}
                            onClick={onClick} />
                    </Collapse>
                ))}
            </TransitionGroup>
        </ul>
    )
}

function TabSingleItem(props: ITabSingleItem) {
    const { activeTab, id, queryType, onClick, onDelete } = { ...props }
    const currentClass = activeTab ? 'query-button fq-tab-active' : 'query-button'

    return (
        <li className={currentClass}>
            <Badge variant="dot" color={'info'} invisible={queryType === 'SOQL'}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={0.5}
                    onClick={(e: any) => onClick(e, id)}
                >
                    {`FQ${id}`}
                    <IconButton
                        onClick={() => onDelete(id)}
                        size={'small'}
                        disableRipple
                        color={'inherit'}>
                        <CloseOutlined fontSize={'small'} />
                    </IconButton>
                </Stack>
            </Badge>
        </li>
    )
}