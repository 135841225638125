import React, {createContext} from "react";
import {ISavedQuery} from "../../interfaces";


const SavedQueriesContext: React.Context<ISavedQueriesContext> = createContext<ISavedQueriesContext>({
    queries: [],
    setQueries: () => {
    }
});
export default SavedQueriesContext;


interface ISavedQueriesContext {
    queries: ISavedQuery[],
    setQueries: (e: any) => any
}