import { Box } from "@mui/system";

import { Autocomplete, TextFieldAutocomplete } from "../../shared";
import { Paper, Stack, Typography } from "@mui/material";
import { useSalesforceObject } from "../../hooks/query";
import { FieldSelector } from "./FieldSelector";
import './index.scss'

export { default as ConditionBlock } from './ConditionBlock'

export default function VisualQueryComposer(props: IVisualQueryComposer) {
    const { state, setState, setFields } = { ...props }
    const { useSalesforceObjects } = useSalesforceObject()
    const { isLoading, data = [] } = useSalesforceObjects()

    const handleChange = (event: string, newValue: any) => {
        setState((current: any) => ({ ...current, [event]: newValue }))
    }


    return (
        <div className={'visual-query-composer-container'}>
            <Paper sx={{ mr: 2, height: '100%' }} variant={'elevation'} elevation={0}>
                <div className={'visual-query-selector'}>
                    <Box>
                        <Typography gutterBottom variant={'body2'} color={'text.secondary'}>From</Typography>
                        <Autocomplete
                            loading={isLoading}
                            disablePortal
                            value={state.object}
                            onChange={(e: any, newValue: any) => handleChange('object', newValue)}
                            size={'small'}
                            options={data}
                            renderOption={(props: any, option: any) => {
                                return (
                                    <li {...props} key={option.name} style={{ display: 'block' }}>
                                        <Typography>
                                            {option.label}&nbsp;

                                        </Typography>
                                        <Typography color={'text.secondary'} variant={'body2'}>
                                            {option.name}
                                        </Typography>
                                    </li>
                                );
                            }}
                            sx={{ width: '100%' }}
                            renderInput={(params: any) => (
                                <TextFieldAutocomplete
                                    {...params}
                                    required
                                    placeholder="Object*"
                                />
                            )}
                        />
                    </Box>
                    <FieldSelector
                        selectedObject={state?.object?.name || ''}
                        values={state}
                        handleChange={handleChange}
                        setFields={setFields}
                    />
                    <Stack direction={'row'} spacing={2}>
                        <div>
                            <Typography gutterBottom variant={'body2'} color={'text.secondary'}>Limit</Typography>
                            <TextFieldAutocomplete type="number"
                                size={'small'}
                                value={state?.limit}
                                onChange={(e: any) => handleChange('limit', e.target.value)}
                                placeholder={'Limit'} />
                        </div>
                        <div>
                            <Typography gutterBottom variant={'body2'} color={'text.secondary'}>Offset</Typography>
                            <TextFieldAutocomplete type="number"
                                size={'small'}
                                value={state?.offset}
                                onChange={(e: any) => handleChange('offset', e.target.value)}
                                placeholder={'Offset'} />
                        </div>
                    </Stack>
                </div>
            </Paper >
        </div >
    )
}


interface IVisualQueryComposer {
    state: vsc,
    setState: (e: any) => any
    setFields: (e: any) => any
}


interface vsc {
    limit?: number | undefined,
    offset?: number | undefined,
    filter?: any,
    object?: any
    orderBy?: {
        field: any,
        direction: 'DESC' | 'ASC' | string,
        nullPosition: 'FIRST' | 'LAST' | string
    },
    fields?: string[]
}