import { Divider, IconButton, Typography } from "@mui/material";
import { GitHub, LinkedIn } from "@mui/icons-material";
import { Link } from 'react-router-dom';

import { Paper } from "../../shared";
import nithin from '../../assets/images/nithin.jpg'
import nithinLogo from '../../assets/images/logo_bro.svg'

export default function FromTheDeveloper() {
    const fontProperty = { fontWeight: 600 }
    return (
        <Paper className={'from-the-developer'} sx={{ p: 2.5 }}>
            <img src={nithin} alt={'nithinpb'} className={'profile-image'} />
            <img src={nithinLogo} alt={'nithinpb'} className={'profile-image-backdrop'} />
            <div className={'socialmedia-link'}>
                <IconButton href={'https://github.com/nithinpachday'} target={'_blank'}>
                    <GitHub />
                </IconButton>
                <IconButton href={'https://www.linkedin.com/in/nithin-p-b-a0818475'} target={'_blank'}>
                    <LinkedIn />
                </IconButton>
            </div>
            <Typography sx={fontProperty}>
                <Link to={'/me'} style={{ textDecoration: 'none' }}>
                    Nithin P B
                </Link>
            </Typography>
            <Typography color={'text.secondary'} gutterBottom>
                Founder
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography color={'text.secondary'} variant={'body2'}>
                Hello! I'am software engineer who loves creating digital magic. When not coding, I'm hiking or watching Netflix.
            </Typography>
        </Paper>
    )
}