import {useEffect} from "react";
import {useQuery} from "../../hooks";

export default function SalesforceCallback() {
    const accessCode = useQuery().get('code')

    useEffect(() => {
        if (window.opener) {
            if (accessCode) window.opener.postMessage({source: '__self', authorizationCode: accessCode});
            window.close();
        }
    });
    return (
        <div>
            Please wait...
        </div>
    )
}
