import { Box } from '@mui/material'
import { useState } from 'react'
import { AccordionDetails, AccordionSummary, AccordionWrapper } from '../../shared'
import { ConditionBlock } from '../VisualQueryComposer'

export default function FieldList() {
    const [state, setState] = useState([])
    return (
        <div>
            <Box>
                <AccordionWrapper>
                    <AccordionSummary>
                        test
                    </AccordionSummary>
                    <AccordionDetails>
                        hello
                    </AccordionDetails>
                </AccordionWrapper>
                <AccordionWrapper>
                    <AccordionSummary>
                        test
                    </AccordionSummary>
                    <AccordionDetails>
                        hello
                    </AccordionDetails>
                </AccordionWrapper>
                <AccordionWrapper>
                    <AccordionSummary>
                        test
                    </AccordionSummary>
                    <AccordionDetails>
                        hello
                    </AccordionDetails>
                </AccordionWrapper>
            </Box>
        </div>
    )
}