import { Typography, Box, Grid, Tabs, Tab, Fade, useTheme, useMediaQuery } from "@mui/material"
import { useState } from 'react'
import { Navigation } from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import { AnyAaaaRecord } from "dns";

const fontProperty = { fontWeight: 600, fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)' }
const westernUnionWork = [
    "Designing and managing cloud infrastructure to ensure optimal performance and availability.",
    "Write modern, performant, maintainable code for internal projects",
    "Communicate with multi-disciplinary teams of engineers on a daily basis for maintenance and optimization",
    "Work with a variety of different languages, platforms, frameworks such as JavaScript, TypeScript, React, Terraform, Cloudformation and BASH"
]

const pumexInfotechWork = [
    "Designed and Delivered high quality web and .NET console applications",
    "Developed and enhanced different cloud data synchronization applications",
    "Designed AI assisted authentication framework with Deep learning networks",
    "Work with a variety of different languages, frameworks such as React, Next, Django ,.NET , Keras, Tensorflow and Wordpress"
]

export function WorkExperience() {
    const [activeTab, setActiveTab] = useState(0);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box sx={{ minHeight: '50vh', mt: smallScreen ? 2 : 10 }}>
            <Typography
                variant={'inherit'}
                component={'span'}
                color={'primary'}
                sx={fontProperty}
                className={'colored-text'}>
                Where  I’ve worked
            </Typography>
            <Typography variant={'body2'} color={'text.secondary'} sx={{ mt: '-5px' }}>
                What i was doing in the past?
            </Typography>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    display: smallScreen ? 'flex' : 'block',
                    mt: 3,
                    mb: 3,
                    gap: 4,
                    maxWidth: 700
                }}
            >
                <StyledTabs
                    value={activeTab}
                    onChange={(e: any, value: number) => setActiveTab(value)}
                    orientation={smallScreen ? "vertical" : "horizontal"}
                    sx={{
                        borderLeft: smallScreen ? 2 : 0,
                        borderBottom: smallScreen ? 0 : 2,
                        mb: smallScreen ? 0 : 2,
                        borderColor: 'divider',
                        minWidth: 180
                    }}
                >
                    <StyledTab label="Western Union" sx={{ textAlign: 'left' }} value={0} />
                    <StyledTab label="Pumex Infotech" sx={{ textAlign: 'left' }} value={1} />
                </StyledTabs>

                {
                    activeTab === 0 && <Box>
                        <Experience
                            role={'Senior Associate'}
                            companyName={'Western Union'}
                            companyLink={'https://www.westernunion.com/'}
                            experienceYear={'August 2022 - Present'}
                            roles={westernUnionWork}
                        />
                    </Box>
                }
                {
                    activeTab === 1 && <Box>
                        <Experience
                            role={'Senior Software Engineer'}
                            companyName={'Pumex Infotech'}
                            companyLink={'https://pumexinfotech.com/'}
                            experienceYear={'August 2019 - August 2022'}
                            roles={pumexInfotechWork}
                        />
                    </Box>
                }
            </Box>

        </Box >
    )
}


function Experience(props: ICompany) {
    const { role, companyName, companyLink, experienceYear, roles } = { ...props }
    return (
        <Fade in={true} timeout={500}>
            <Box>
                <Box >
                    <Typography
                        variant={'inherit'}
                        component={'span'}
                        color={'text.primary'}
                        sx={{ fontSize: 'clamp(1rem, 0.7643rem + 1vw, 1.25rem)' }}>
                        {role}
                        <Typography
                            variant={'inherit'}
                            color={'primary'}
                            target={'_blank'}
                            component={'a'}
                            href={companyLink}
                            sx={{ fontSize: 'clamp(1rem, 0.7643rem + 1vw, 1.25rem)' }}
                            className={'colored-text'}>
                            &nbsp;@{companyName}
                        </Typography>
                    </Typography>
                    <Typography variant="body2">
                        {experienceYear}
                    </Typography>
                </Box>
                <Box>
                    <Grid container sx={{ flex: 1, width: 'auto', mt: 1 }} spacing={1}>
                        {
                            roles.map((e: string, index: number) => (
                                <Grid item xs={12} key={index}>
                                    <Box display={'flex'} alignItems={'start'} gap={1}>
                                        <Navigation sx={{ fontSize: 'small', transform: 'rotate(90deg)', marginTop: '4px' }} color={'primary'} />
                                        <Typography sx={{ maxWidth: 700 }}> {e} </Typography>
                                    </Box>
                                </Grid>
                            )
                            )
                        }
                    </Grid>
                </Box>
            </Box>
        </Fade>
    )
}



const StyledTab = styled((props: any) => (
    <Tab disableRipple {...props} />
))(({ theme }) => {
    console.log(theme.breakpoints.up('sm'))
    return {
        alignItems: 'flex-start',
        textTransform: 'none',
        borderLeft: `2px solid transparent`,

        '&.Mui-selected': {
            color: theme.palette.primary.main,
            background: `linear-gradient(45deg, #0066f214, 10%, transparent)`,
            borderLeft: `2px solid ${theme.palette.primary.main}`,
            borderBottom: 'none',
            '@media (max-width: 600px)': {
                borderLeft: 'none',
                borderBottom: `2px solid ${theme.palette.primary.main}`
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: `#006aff12`,
        },
    }
});


const StyledTabs = styled((props: any) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
    },
});



interface ICompany {
    role: string,
    companyName: string,
    companyLink: string,
    experienceYear: string,
    roles: string[]
}