import './styles/object-list.scss'
import { Button, Checkbox, ClickAwayListener, Stack, Typography } from "@mui/material"
import { FilterListRounded } from '@mui/icons-material'
import { Popper } from '../../shared'
import { useState } from 'react'

const tes = [1, 2, 3, 5, 6, 34, 345, 345, 345, 345, 234, 2345, 436, 2, 34, 26, 23, 46, 5, 3, 6]

export default function ObjectList() {
    return (
        <div className="object-list-container">
            <Control />
            <ul className='object-list-body'>
                {tes.map((_, index) => <List activeId={12} id={index} apiName={'tesing'} label={'Test'} />)}
            </ul>
        </div>
    )
}

function Control() {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClickAway = () => {
        setAnchorEl(null)
    }

    return (
        <div className="OL-Search">
            <input placeholder='Search' />
            <Button
                sx={{ borderRadius: 2, px: 2, color: "text.secondary" }}
                color={"inherit"}
                variant={"contained"}
                className={"OL-control-button"}
                disableElevation
                disableRipple
                onClick={(e: any) => setAnchorEl(e.currentTarget)}
                startIcon={<FilterListRounded />}>
                Filter
            </Button>
            <Popper anchorEl={anchorEl} open={Boolean(anchorEl)}>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <ul className={'OL-filter-options'}>
                        <li>Custom Objects Only</li>
                        <li>Standard Objects Only</li>
                        <li>Deprecated Or Hidden</li>
                    </ul>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}


function List(props: IList) {
    const { activeId, id, apiName, label } = { ...props }
    return (
        <li className={`OL-list ${id === activeId && 'OL-list-selected'}`}>
            <Stack
                sx={{ flexBasis: '250px' }}
                direction={'row'}
                spacing={1}
                alignItems={'center'}>
                {/* <Checkbox size={'small'} /> */}
                <Typography
                    className={'OL-l-label'}
                    color={id === activeId ? 'inherit' : 'text.primary'}
                    variant={'h6'}>
                    {label}
                </Typography>
            </Stack>

            <Typography color={id === activeId ? 'inherit' : 'text.secondary'}>
                {apiName}
            </Typography>
        </li>
    )
}


interface IList {
    activeId: any,
    id: any,
    apiName: string,
    label: string
}

