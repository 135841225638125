import { useEffect, useState, memo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { SideDrawer, NewsFeed, QueryWindow, LargeScreenRequired } from "../../components";
import { IQueryTabObject, ISavedQuery } from "../../interfaces";
import { QueryTabContext, SavedQueriesContext } from '../../providers/context'
import { fallbackQuery } from "../../constants";
import './index.scss'


export default function Dashboard() {
    const theme = useTheme();
    const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [queries, setQueries] = useState<ISavedQuery[]>(JSON.parse(localStorage.getItem('fq-sq') as string) || [])

    useEffect(() => {
        document.documentElement.style.setProperty('--fq-header-max-width', '100%');
    }, [])

    return (
        <SavedQueriesContext.Provider value={{ queries, setQueries }}>
            {!mediumScreen ? <LargeScreenRequired /> : <MemorizedDashboard />}
        </SavedQueriesContext.Provider>
    )
}


const MemorizedDashboard = memo(function MemorizedDashboard() {
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [tabQueries, setTabQueries] = useState<IQueryTabObject[]>([{
        id: 1,
        queryString: fallbackQuery,
        queryType: 'SOQL',
        editorMode: 'QUERY'
    }])

    return (
        <div className={'dashboard-container'}>
            <SideDrawer />
            <QueryTabContext.Provider value={{ tabQueries, setTabQueries }}>
                <QueryWindow />
            </QueryTabContext.Provider>
            {largeScreen && <NewsFeed />}
        </div>
    )
})
