import { useParams } from 'react-router-dom'
import { Divider } from "@mui/material"
import { ShifterLocalMain, ShifterNavigation } from "../../components"
import './index.scss'

export default function ShifterMethod() {
    const { storageProviderType } = useParams()
    return (
        <div className={"ShifterMethodWrapper"}>
            <ShifterNavigation />
            <Divider />
            <ComponentDecider storageProviderType={storageProviderType} />
        </div>
    )
}



function ComponentDecider(props: IComponentDecider) {
    const { storageProviderType = "" } = { ...props }
    if (storageProviderType === "local") return <ShifterLocalMain />
    return <>401 - not allowed method</>
}

interface IComponentDecider {
    storageProviderType?: string
}