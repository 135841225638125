import {memo} from 'react';
import {Button, Switch} from "../../shared";
import {Divider, Typography} from "@mui/material";
import './index.scss'

export function QueryOperationSelector(props: IQueryOperationSelector) {
    const {active = false, disabled, onSelectionChange, onFormat} = {...props}


    const handleSelectionChange = (e: any) => {
        onSelectionChange(e.target.checked)
    }

    return (
        <div className={'query-operation-selector'}>
            <div>
                <Switch checked={active} onChange={handleSelectionChange} disabled={disabled}/>
                <Typography sx={{fontWeight: 600}} variant={'button'} color={'text.secondary'}> SOSL </Typography>
            </div>
            <Divider flexItem orientation={'vertical'}/>
            <Button variant={'contained'}
                    onClick={onFormat}
                    size={'small'}
                    sx={{borderRadius: 2}}>
                FORMAT
            </Button>
        </div>
    )
}

export default memo(QueryOperationSelector)

interface IQueryOperationSelector {
    active: boolean,
    onSelectionChange: (e: any) => any,
    disabled: boolean,
    onFormat: () => any
}