import { useEffect, useMemo } from "react";
import { Box, Checkbox, Typography } from "@mui/material"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useSalesforceObject } from "../../hooks/query"
import { TextFieldAutocomplete, Autocomplete } from "../../shared"
import OrderBlock from "./OrderBlock";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function FieldSelector(props: IFieldSelector) {
    const { selectedObject, handleChange, values, setFields } = { ...props }
    const { useSalesforceSingleObject } = useSalesforceObject()
    const { isLoading, isSuccess, data = [], refetch } = useSalesforceSingleObject(selectedObject)

    useEffect(() => {
        const getObjectDetails = async () => {
            const { data: result } = await refetch()
            setFields(result)
        }

        if (selectedObject) {
            handleChange('fields', [])
            getObjectDetails().then()
        }
    }, [selectedObject])

    const dataProcess = useMemo(() => {
        return data.sort(function (a: any, b: any) {
            var valueA = a.referenceTo[0] || selectedObject;
            var valueB = b.referenceTo[0] || selectedObject;

            if (valueA < valueB) {
                return -1;
            } else if (valueA > valueB) {
                return 1;
            } else {
                return 0;
            }
        });
    }, [data, selectedObject])

    return (
        <>
            <Box>
                <Typography gutterBottom variant={'body2'} color={'text.secondary'}>Fields</Typography>
                <Autocomplete
                    disablePortal
                    onChange={(e: any, newValue: any) => handleChange('fields', newValue)}
                    size={'small'}
                    options={dataProcess}
                    multiple
                    value={values.fields}
                    groupBy={(option: any) => option?.referenceTo[0] || selectedObject}
                    disabled={!selectedObject}
                    loading={isLoading}
                    sx={{ width: '100%' }}
                    disableCloseOnSelect
                    renderOption={(props: any, option: any, { selected }: { selected: any }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params: any) => (
                        <TextFieldAutocomplete
                            {...params}
                            required
                            placeholder="Fields*"
                        />
                    )}
                />
            </Box>
            <Box>
                <OrderBlock
                    fields={dataProcess}
                    onOrderByChange={(e: any) => handleChange('orderBy', e)}
                    orderByValue={values.orderBy}
                />
            </Box>
        </>
    )
}

interface IFieldSelector {
    selectedObject: string,
    handleChange: any,
    values: any,
    setFields: (e: any) => any
}