import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ContactForm } from "../../components";
import './index.scss'
import FromTheDeveloper from "./FromTheDeveloper";

export default function Contact() {
    const fontProperties = { fontWeight: 500 }
    const fontProperties2 = { fontWeight: 600 }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={'width-adjust about-wrapper'}>
            <Grid container
                spacing={2}
                alignItems={'center'}
                className={'full-height'}>
                <Grid item xs={12} md={6}>
                    <div className={'about-main-content'}>
                        <Typography
                            color={'text.secondary'}
                            sx={fontProperties}
                            gutterBottom>
                            Contact
                        </Typography>
                        <Typography variant={'h4'}
                            sx={{ ...fontProperties2, mb: 1.5 }}
                            className={'colored-text'}>
                            Making it Simple :|
                        </Typography>
                        <Typography
                            variant={'subtitle1'}
                            color={'text.secondary'}>
                            Want to learn more about our product? Just have a question? Let us know how we
                            can help and we'll be in touch soon.
                        </Typography>
                        <Box sx={{ mt: 3, mb: 2 }}>
                            <FromTheDeveloper />
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ContactForm />
                </Grid>
            </Grid>
        </div>
    )
}