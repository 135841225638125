import {Alert, AlertTitle, Box, Collapse, Typography} from "@mui/material";
import {IErrorAlert} from "../../interfaces";

export default function ErrorAlert(props: IErrorAlert) {
    const {errorMessage, open} = {...props}
    return (
        <Box sx={{width: '100%', maxWidth: '100%'}}>
            <Collapse in={open}>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <Typography variant={'body2'}>
                        Error occurred —
                        <Typography sx={{fontWeight: 500}} component={'span'} variant={'body2'}>
                            &nbsp; {errorMessage}
                        </Typography>
                    </Typography>
                </Alert>
            </Collapse>
        </Box>
    )
}