import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import './index.scss'

export default function QueryWindowSelector(props: IQueryWindowSelector) {
    const { editorMode, onEditorModeChange } = { ...props }
    return (
        <div className={'query-window-selector-wrapper'}>
            <div className={'query-window-container'}>
                <ToggleButtonGroup size={'small'}
                    className={'qw-item-wrapper'}
                    exclusive
                    onChange={(e: any, value: string) => onEditorModeChange(value)}
                    value={editorMode}>
                    <ToggleButton
                        className={'qw-item'}
                        value={'VISUAL'}
                        color={'primary'}>
                        Visual
                    </ToggleButton>
                    <ToggleButton
                        value={'QUERY'}
                        color={'primary'}
                        className={'qw-item'}>
                        QUERY
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
    )
}

interface IQueryWindowSelector {
    onEditorModeChange: (e: any) => any,
    editorMode: string
}