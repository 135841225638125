import React, {memo, useEffect, useState} from "react";
import {SalesforceInstanceContext, AuthContext} from './context'
import {Typography} from "@mui/material";
import service from '../services'
import {useLocation} from "react-router-dom";

export default function GlobalProvider(props: IGlobalProvider) {
    const {children} = {...props}
    const {pathname} = useLocation();
    const [salesforceInstance, setSalesforceInstance] = useState<string>('')
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})


    useEffect(() => {
        if (pathname === '/dashboard' && !salesforceInstance) {
            reauthenticate().then()
        }
    }, [pathname])


    const reauthenticate = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const {data} = await service.salesforceAuth().salesforceRefreshToken()
            if (data.data.accessToken) {
                setLoggedIn(true)
                setSalesforceInstance(data.data.instanceUrl)
            }
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            setApiProgress({loading: false, ready: true, error: false})
        }
    }


    return (
        <AuthContext.Provider value={{loggedIn, setLoggedIn}}>
            <SalesforceInstanceContext.Provider value={{salesforceInstance, setSalesforceInstance}}>
                <MemorizedComponent>
                    <>
                        {apiProgress.loading && <Typography> Loading ... </Typography>}
                        {!apiProgress.loading && children}
                    </>
                </MemorizedComponent>
            </SalesforceInstanceContext.Provider>
        </AuthContext.Provider>
    )
}


interface IGlobalProvider {
    children: JSX.Element | JSX.Element[]
}


function MemoComponent({children}: { children: JSX.Element | JSX.Element[] }) {
    return (
        <>{children}</>
    )
}

const MemorizedComponent = memo(MemoComponent)