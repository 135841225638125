export default function VideoPlayer() {
    return (
        <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/8CZ8Sl3CvX8?autoplay=1`}
            frameBorder="0"
            allow="accelerometer;clipboard-write; encrypted-media; gyroscope;"
            allowFullScreen
            title="Embedded youtube"
        />
    )
}