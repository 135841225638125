import { useState } from "react"
import { Divider } from "@mui/material"

import { Editor, VisualQueryComposer } from "..";
import { ConditionBlock } from "../VisualQueryComposer";

export default function VisualQueryBuilder(props: IVisualQueryBuilder) {
    const { value, onValueChange, state, setState } = { ...props }
    const [fields, setFields] = useState([])

    return (
        <div className={'dashboard-editor-visual-container'}>
            <VisualQueryComposer state={state} setState={setState} setFields={setFields} />
            <Divider flexItem orientation={'vertical'} />
            <div className={'dashboard-query-wrapper'}>
                <ConditionBlock filterRules={state?.filter} onFilterChange={setState} fields={fields} />
                <div className={'dashboard-query-editor-container'}>
                    <Editor
                        readOnly={true}
                        value={value}
                        onValueChange={onValueChange}
                    />
                </div>
            </div>
        </div>
    )
}

interface IVisualQueryBuilder {
    value: string,
    onValueChange: (e: any) => any,
    state: vsc,
    setState: (e: any) => any
}

interface vsc {
    limit?: number | undefined,
    offset?: number | undefined,
    filter?: any,
    object?: any
    orderBy?: {
        field: any,
        direction: 'DESC' | 'ASC' | string,
        nullPosition: 'FIRST' | 'LAST' | string
    },
    fields?: string[]
}