import { LinkedIn, Twitter, Instagram, Facebook, GitHub } from "@mui/icons-material"

export function SocialMedia() {
    return (
        <>
            <div className="me-social-media">
                <ul >
                    {
                        socialMedia.map((e: any) => <li><a href={e.link} key={e.name} target={'_blank'} rel="noreferrer"> {e.icon}</a></li>)
                    }
                </ul>
            </div>

            <div className="me-social-media me-freelancing">
                <div>
                    <a href="mailto:nithinpb.com@gmail.com">nithinpb360@gmail.com</a>
                </div>
            </div>
        </>
    )
}


const socialMedia = [
    {
        name: 'LinkedIn',
        icon: <LinkedIn sx={{ fontSize: 26 }} />,
        link: "https://www.linkedin.com/in/nithin-p-b-a0818475/"
    },
    {
        name: 'Github',
        icon: <GitHub sx={{ fontSize: 26 }} />,
        link: "https://github.com/nithinpachday"
    },
    {
        name: 'Instagram',
        icon: <Instagram sx={{ fontSize: 26 }} />,
        link: "https://www.instagram.com/nithin_pb_pachady"
    },
    {
        name: 'Facebook',
        icon: <Facebook sx={{ fontSize: 26 }} />,
        link: "https://www.facebook.com/nithin.pachady.3"
    }
]