import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { Autocomplete, TextFieldAutocomplete } from "../../shared";

export default function OrderBlock(props: IOrderBlock) {
    const { fields, onOrderByChange, orderByValue } = { ...props }

    const handleChange = (key: string, value: any) => {
        onOrderByChange({ ...orderByValue, [key]: value })
    }

    return (
        <div>
            <div>
                <Typography gutterBottom variant={'body2'} color={'text.secondary'}>Order By</Typography>
                <Autocomplete
                    disablePortal
                    size={'small'}
                    value={orderByValue.field}
                    onChange={(e: any, value: any) => handleChange('field', value)}
                    options={fields}
                    sx={{ width: '100%' }}
                    renderInput={(params: any) => (
                        <TextFieldAutocomplete
                            {...params}
                            placeholder="Order By"
                        />
                    )}
                />
                <Stack direction={'row'}
                    spacing={2}
                    className={'order-control-wrapper'}
                >
                    <div className={'order-block-item'}>
                        <Typography gutterBottom variant={'body2'} color={'text.secondary'}>Direction</Typography>
                        <Select
                            disabled={!orderByValue.field}
                            className={'order-block-select-1'}
                            size={'small'}
                            value={orderByValue.direction}
                            onChange={(e) => handleChange('direction', e.target.value)}
                        >
                            <MenuItem value={'DESC'}>DESC</MenuItem>
                            <MenuItem value={'ASC'}>ASC</MenuItem>
                        </Select>
                    </div>
                    <div className={'order-block-item'}>
                        <Typography gutterBottom variant={'body2'} color={'text.secondary'}>Nulls</Typography>
                        <Select
                            disabled={!orderByValue.field}
                            onChange={(e) => handleChange('nullPosition', e.target.value)}
                            className={'order-block-select-2'}
                            value={orderByValue.nullPosition}
                            size={'small'}
                        >
                            <MenuItem value={'LAST'}>LAST</MenuItem>
                            <MenuItem value={'FIRST'}>FIRST</MenuItem>
                        </Select>
                    </div>
                </Stack>
            </div>
        </div>
    )
}
interface IOrderBlock {
    fields: any,
    onOrderByChange: (e: any) => any,
    orderByValue: any
}