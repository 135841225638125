import CodeMirror from "codemirror";
import { snippets } from './snippets'

export function getSnippets(codemirror, readOnly) {
    if (readOnly) return
    CodeMirror.showHint(codemirror, function () {
        let cursor = codemirror.getCursor();
        let line = codemirror.getLine(cursor.line);
        if (line.replaceAll(" ", "").length < 1) return
        let start = cursor.ch, end = cursor.ch;
        // corrects ignoring trailing whitespaces removal
        while (start && /\w/.test(line.charAt(start - 1)))
            --start;
        while (end < line.length && /\w/.test(line.charAt(end)))
            ++end;

        const token = codemirror.getTokenAt(cursor);
        const currentWord = token.string;

        // reduce hint options if user has already entered something
        const list = snippets.filter((item) => item.displayName.includes(currentWord.toUpperCase()))

        return {
            list: list,
            from: CodeMirror.Pos(cursor.line, start),
            to: CodeMirror.Pos(cursor.line, end)
        };
    }, { completeSingle: false });
}