import largeScreenRequiredImg from '../../assets/images/large_screen.jpg'
import './index.scss'
import {Typography} from "@mui/material";

export default function LargeScreenRequired() {
    return (
        <div className={'LargeScreenRequired'}>
            <Typography align={'center'} variant={'h6'}
                        color={'text.secondary'}
                        sx={{mt: 2}}>
                I need some more space to view :)
            </Typography>
            <img src={largeScreenRequiredImg} alt={'large-screen-required'}/>
        </div>
    )
}