import { Outlet } from 'react-router-dom';
import { Header, Footer } from '../components';

export default function GeneralLayout() {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}