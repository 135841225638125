import React, {useState, useEffect} from "react";
import {useForm, useSalesforceInstance, useAuth} from "../../hooks";
import {useSalesforceUsernamePasswordAuth} from "../../hooks/query";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    CircularProgress,
    Collapse,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack, Typography, useMediaQuery, useTheme
} from "@mui/material";
import {WarningAmberRounded} from "@mui/icons-material";
import {Button, Paper, TextField} from "../../shared";
import {apiErrorProgress} from "../../helper";
import OAuth from "./OAuth";
import {routes} from '../../constants/routes'
import './index.scss'


const INITIAL_VALUE = {
    username: '',
    password: '',
    securityToken: '',
    loginUrl: '',
    customUrl: ''
}

export default function SalesforceLoginForm() {
    const {state} = useLocation();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate()
    const {setSalesforceInstance, salesforceInstance} = useSalesforceInstance()
    const {setLoggedIn, loggedIn} = useAuth()
    const authenticate = useSalesforceUsernamePasswordAuth()
    const [environment, setEnvironment] = useState<string>('production')
    const [formValue, setFormValue] = useForm(INITIAL_VALUE)
    const buttonEnabled = formValue.username && formValue.password

    const fetchData = async () => {
        try {
            const params = formValue
            if (environment === 'production') params['loginUrl'] = 'https://login.salesforce.com'
            if (environment === 'sandbox') params['loginUrl'] = 'https://test.salesforce.com'
            if (environment === 'custom') params['loginUrl'] = formValue['customUrl']
            const {data} = await authenticate.mutateAsync(params)
            setSalesforceInstance(data.instanceUrl)
            setLoggedIn(true)
        } catch (e) {
            console.log(authenticate.isError, apiErrorProgress(authenticate.error))
        }
    }

    useEffect(() => {
        if (loggedIn && salesforceInstance) {
            if (state?.from?.pathname) navigate(state.from.pathname)
            else navigate(routes.DASHBOARD)
        }
    }, [loggedIn, salesforceInstance])

    const handleValueChange = (e: any) => {
        setFormValue(e)
    }

    const breakStyles = {
        smallScreen: {p: 3, px: 2, maxWidth: 400, zIndex: 1},
        largeScreen: {
            p: 3,
            px: 5,
            maxWidth: '100%',
            borderTop: '4px solid',
            borderColor: 'secondary.main',
            zIndex: 1
        }
    }

    return (
        <Paper sx={!smallScreen ? breakStyles.smallScreen : breakStyles.largeScreen}
               variant={'elevation'}
               elevation={!smallScreen ? 0 : 1}>
            <Paper sx={{width: 375, m: 'auto', p: 1, maxWidth: '100%'}}
                   variant={'elevation'}
                   elevation={0}>
                <Typography variant={'h5'}
                            color={'text.primary'}
                            gutterBottom
                            sx={{mt: 1, fontWeight: 600}}>
                    Manage your Salesforce data with
                    <Link to={'/'} className={'colored-text non-styled-link'}> nithinpb.com</Link>!
                </Typography>
                <Typography variant={'body2'}>
                    Access your salesforce with world's first dedicated and trusted Query as a service
                    platform
                </Typography>
                <Stack spacing={2} sx={{mt: 2}}>
                    <TextField label={'Username'}
                               fullWidth
                               required
                               autoFocus
                               onChange={handleValueChange}
                               name={'username'}/>
                    <TextField label={'Password'}
                               required
                               type={'password'}
                               onChange={handleValueChange}
                               name={'password'}/>
                    <TextField label={'Security Token'}
                               type={'password'}
                               onChange={handleValueChange}
                               name={'securityToken'}/>
                    <FormControl style={{marginTop: 16, marginBottom: -16}} component="fieldset">
                        <FormLabel component="legend">You are about to login to</FormLabel>
                        <RadioGroup row name="url" value={environment} onChange={(e) => setEnvironment(e.target.value)}>
                            <FormControlLabel color={"primary"}
                                              value="production"
                                              control={<Radio color="primary"/>}
                                              label="Production"/>
                            <FormControlLabel color={"primary"}
                                              value="sandbox"
                                              control={<Radio color="primary"/>}
                                              label="Sandbox"/>
                            <FormControlLabel color={"primary"}
                                              value="custom"
                                              control={<Radio color="primary"/>}
                                              label="Custom"/>
                        </RadioGroup>
                    </FormControl>
                    <Collapse in={environment === 'custom'}>
                        <TextField label="Custom URL" fullWidth sx={{mt: 1}} onChange={handleValueChange}
                                   name={'customUrl'}/>
                    </Collapse>
                    <ErrorMessage open={authenticate.isError}
                                  errorMessage={apiErrorProgress(authenticate.error) || ''}
                    />
                    <Button variant={'contained'}
                            disabled={!buttonEnabled}
                            disableElevation
                            onClick={fetchData}
                            sx={{borderRadius: '10px', height: 45}}>
                        {
                            authenticate.isLoading ? <CircularProgress color={'inherit'} size={24}/> : 'Login'
                        }
                    </Button>
                    <OAuth salesforceEnvironment={environment} customUrl={formValue['customUrl']}/>
                    <PolicyTerms/>
                </Stack>
            </Paper>
        </Paper>
    )
}


function PolicyTerms() {
    return (
        <Typography variant={'caption'} color={'text.secondary'}>
            By logging in, you are agreeing to nithinpb.com
            <Typography component={'span'} variant={'inherit'}>
                &nbsp;
                <Link to={routes.TERMS_OF_SERVICE}>
                    Terms of Service
                </Link> for usage and&nbsp;
                <Link to={routes.COOKIE_POLICY}>
                    Cookie policy
                </Link>
            </Typography>
        </Typography>
    )
}

function ErrorMessage({open, errorMessage}: { open: boolean, errorMessage: any }) {
    return (
        <Collapse in={open}>
            <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'} sx={{mt: -1, mb: 0}}>
                <WarningAmberRounded color={'error'} style={{marginTop: -2}}/>
                <Typography color={'error'}>
                    {errorMessage?.toString()?.replaceAll('Error:', '')}
                </Typography>
            </Stack>
        </Collapse>
    )
}
