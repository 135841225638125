import { useEffect, useState } from "react"
import { Box, CircularProgress, Divider, IconButton, LinearProgress, Stack, Typography, Snackbar, Alert, Tooltip, Collapse } from "@mui/material"
import { ContentPasteOutlined, EmailOutlined, WhatsApp, ReportProblemOutlined, KeyboardArrowUp, SaveOutlined, RefreshOutlined, ClearAll, KeyboardArrowDown } from "@mui/icons-material"
import { useParams } from "react-router-dom"

import { Editor, NotepadHeader } from "../../components"
import { Button, Paper } from "../../shared"
import { useQuery } from "../../hooks"
import { useNotepad } from "../../hooks/query"
import './index.scss'

export default function Notepad() {
    const [state, setState] = useState('')
    const [changeIdentified, setChangeIdentified] = useState(false)
    const [toastState, setToastState] = useState(false)
    const [notepadId, setNotepadId] = useState<string | undefined>('')

    const fetchQueryData = useQuery().get('fetch')
    const paramsData = useParams()

    const { useGetNote, useWriteNote } = useNotepad()
    const { isLoading, data, isSuccess, isError, error, mutateAsync } = useGetNote()
    const writeNote = useWriteNote()

    const fetchData = async () => {
        try {
            const params = {
                id: paramsData.id
            }
            const response = await mutateAsync(params)
            setState(response)
            setToastState(true)
        } catch (e) {
            setToastState(true)
            console.log(e)
        }
    }




    const handleChange = (value: any) => {
        setState(value)
        setChangeIdentified(true)
    }

    const handleSave = async (clear: boolean = false) => {
        try {
            const params = {
                id: notepadId,
                note: clear ? 'cleared' : state
            }
            await writeNote.mutateAsync(params)
            setChangeIdentified(false)
            setToastState(true)
            if (clear) {
                setState('cleared')
            }
        } catch (e) {
            setToastState(true)
        }
    }

    useEffect(() => {
        setNotepadId(paramsData.id)
        fetchData().then()
    }, [fetchQueryData, paramsData.id])


    useEffect(() => {
        document.documentElement.style.setProperty('--fq-header-max-width', '100%');
        return () => {
            document.documentElement.style.setProperty('--fq-header-max-width', '1200px');
        }
    }, [])

    return (
        <div className="NotePadContent-Wrapper">
            <NotepadHeader notepadId={notepadId || 'unknown'} warning={changeIdentified} />
            {writeNote.isError && <SaveStatus
                open={toastState}
                handleClose={() => setToastState(false)}
                severity={'error'}
                message={`Cannot save/load this note. ${error}`}
            />}
            {writeNote.isSuccess && <SaveStatus
                open={toastState}
                handleClose={() => setToastState(false)}
                severity={'success'}
                message={'Saved!'}
            />}
            <div className={'notedrop-editor-container'}
                style={{
                    width: '100vw', height: 'calc(100vh - 70px)',
                    overflow: 'hidden', display: 'flex', position: 'relative'
                }}>
                {(isLoading || writeNote.isLoading) && <LoadingSpinner />}
                <Box sx={{ flex: 1, position: 'relative' }} className="notepad-editor">
                    <Editor
                        value={state}
                        onValueChange={handleChange}
                        mode={undefined}
                    />
                    {changeIdentified &&
                        <Tooltip title={'unsaved changes in memory'}>
                            <ReportProblemOutlined
                                sx={{ position: 'absolute', top: 16, right: 24 }}
                                color={'warning'}
                                className="blink_me"
                                fontSize="large" />
                        </Tooltip>
                    }
                </Box>
                <Divider orientation={'vertical'} className="notepad-editor-divider" />
                <SideNav
                    onRefresh={fetchData}
                    onSave={() => handleSave(false)}
                    onClear={() => handleSave(true)}
                    apiState={writeNote}
                    id={notepadId} />
                <SideNavMobile
                    onRefresh={fetchData}
                    onSave={() => handleSave(false)}
                    onClear={() => handleSave(true)}
                    apiState={writeNote}
                    id={notepadId} />
            </div>
        </div>

    )
}


function SideNav(props: any) {
    const textStyle = {
        textDecoration: 'none',
        textTransform: 'none',
        borderRadius: 2
    }
    return (
        <Box sx={{ width: '320px', p: 2, display: 'flex', flexDirection: 'column', gap: 2 }} className={'notepad-sidenav-large'}>
            <Button variant={'contained'}
                sx={{ ...textStyle }}
                onClick={props.onSave}
                disabled={props.apiState.isLoading}>
                {props.apiState.isLoading ?
                    <Box display={'flex'} gap={2}>
                        <CircularProgress size={'16px'} />
                        <Typography fontSize={'inherit'}>
                            Saving...
                        </Typography>
                    </Box> :
                    <Typography fontSize={'inherit'}>Save</Typography>
                }

            </Button>
            <Button
                variant={'contained'}
                disabled={props.apiState.isLoading}
                sx={{ ...textStyle }}
                color={'primary'}
                onClick={props.onRefresh}>
                Refresh
            </Button>
            <Button
                variant={'contained'}
                disabled={props.apiState.isLoading}
                sx={{ ...textStyle }}
                color={'error'}
                onClick={props.onClear}>
                Clear
            </Button>


            <Box>
                <Paper sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 600, p: 1, pl: 2 }}>
                        Share
                    </Typography>
                    <Divider sx={{ width: '100%' }} />
                    <Typography sx={{
                        maxWidth: '100%',
                        fontWeight: 400, p: 1, pl: 2, pr: 2,
                        whiteSpace: 'pre-line', wordWrap: 'break-word'

                    }}
                        variant="body2">
                        Share notes effortlessly with NoteDrop!<br />
                        Id: {props.id}.<br />
                        Let's stay organized together.
                        <br />
                        <br />
                        -NoteDrop
                    </Typography>
                    <Divider sx={{ width: '100%' }} />
                    <Stack direction={'row'} gap={2} mt={0.5} alignItems={'center'} p={0.5} pl={2}>
                        <SocialMediaShare icon={ContentPasteOutlined} onClick={() => navigator.clipboard.writeText(`https://www.nithinpb.com/notedrop/${props.id}`)} />
                        <SocialMediaShare icon={WhatsApp} link={`whatsapp://send?text=Share notes effortlessly with NoteDrop!%0D%0AClick here: https://www.nithinpb.com/notedrop/${props.id}.%0D%0ALet's stay organized together. %0D%0A %0D%0A-NoteDrop`} />
                        <SocialMediaShare icon={EmailOutlined} link={`mailto:?subject=Note Sharing | NoteDrop ${props.id}&body=Share notes effortlessly with NoteDrop!%0D%0AClick here: https://www.nithinpb.com/notedrop/${props.id}.%0D%0ALet's stay organized together. %0D%0A %0D%0A-NoteDrop`} />
                    </Stack>
                </Paper>
            </Box>
        </Box>
    )
}

function SideNavMobile(props: any) {

    const [openExtra, setOpenExtra] = useState(false)

    const textStyle = {
        textDecoration: 'none',
        textTransform: 'none',
        borderRadius: 2
    }


    const handleExtra = () => {
        setOpenExtra((current: any) => !current)
    }

    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: openExtra ? 2 : 0 }} className={'notepad-sidenav-small'}>
            <Collapse in={openExtra} orientation={'vertical'}>
                <Box>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: 600, p: 1, pl: 2 }}>
                            Share
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        <Typography sx={{
                            maxWidth: '100%',
                            fontWeight: 400, p: 1, pl: 2, pr: 2,
                            whiteSpace: 'pre-line', wordWrap: 'break-word'

                        }}
                            variant="body2">
                            Share notes effortlessly with NoteDrop!<br />
                            Id: {props.id}.<br />
                            Let's stay organized together.
                            <br />
                            <br />
                            -NoteDrop
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        <Stack direction={'row'} gap={2} mt={0.5} alignItems={'center'} p={0.5} pl={2}>
                            <SocialMediaShare icon={ContentPasteOutlined} onClick={() => navigator.clipboard.writeText(`https://www.nithinpb.com/notedrop/${props.id}`)} />
                            <SocialMediaShare icon={WhatsApp} link={`whatsapp://send?text=Share notes effortlessly with NoteDrop!%0D%0AClick here: https://www.nithinpb.com/notedrop/${props.id}.%0D%0ALet's stay organized together. %0D%0A %0D%0A-NoteDrop`} />
                            <SocialMediaShare icon={EmailOutlined} link={`mailto:?subject=Note Sharing | NoteDrop ${props.id}&body=Share notes effortlessly with NoteDrop!%0D%0AClick here: https://www.nithinpb.com/notedrop/${props.id}.%0D%0ALet's stay organized together. %0D%0A %0D%0A-NoteDrop`} />
                        </Stack>
                    </Paper>
                </Box>
            </Collapse>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={2} justifyContent={'flex-start'}>
                    <Button variant={'contained'}
                        sx={{ ...textStyle }}
                        onClick={props.onSave}
                        startIcon={<SaveOutlined />}
                        disabled={props.apiState.isLoading}>
                        {props.apiState.isLoading ?
                            <Box display={'flex'} gap={2}>
                                <CircularProgress size={'16px'} />
                                <Typography fontSize={'inherit'}>
                                    Saving...
                                </Typography>
                            </Box> :
                            <Typography fontSize={'inherit'}>Save</Typography>
                        }

                    </Button>
                    <Button
                        startIcon={<RefreshOutlined />}
                        variant={'contained'}
                        disabled={props.apiState.isLoading}
                        sx={{ ...textStyle }}
                        color={'primary'}
                        onClick={props.onRefresh}>
                        Refresh
                    </Button>
                    <Button
                        startIcon={<ClearAll />}
                        variant={'contained'}
                        disabled={props.apiState.isLoading}
                        sx={{ ...textStyle }}
                        color={'error'}
                        onClick={props.onClear}>
                        Clear
                    </Button>
                </Stack>
                <IconButton onClick={handleExtra}>
                    {openExtra ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </IconButton>
            </Stack>

        </Box>
    )
}

function SocialMediaShare(props: any) {
    const { icon: Icon, link, onClick = () => { } }: { icon: any, link: any, onClick: any } = { ...props }
    return (
        <Box>
            <a href={link}>
                <IconButton size={'small'} onClick={onClick}>
                    <Icon sx={{}} />
                </IconButton>
            </a>
        </Box>
    )
}

function LoadingSpinner() {
    return (
        <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 15000 }}>
            <LinearProgress />
        </div>
    )
}

function SaveStatus(props: any) {
    return (
        <Snackbar open={props.open} autoHideDuration={1000} onClose={props.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <Alert onClose={props.handleClose} severity={props.severity} sx={{ width: '100%', borderRadius: 2, border: '1px solid #18bf54' }} >
                {props.message}
            </Alert>
        </Snackbar>
    )
}