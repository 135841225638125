import React from "react";
import { Controlled as ControlledCodeMirrorEditor } from 'react-codemirror2'
import { IEditor } from "../../interfaces";
import CodeMirror from 'codemirror'
import { salesforceMode } from './salesforce-mode'
import { getSnippets } from './utils'
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
import 'codemirror/theme/material.css'
import './forcequery-codemirror.scss'
import './index.scss'

//CodeMirror.defineMode('salesforceMode', salesforceMode)

export default function Editor(props: IEditor) {
    const { value = '', onValueChange, readOnly = false, mode = 'salesforceMode' } = { ...props }

    const handleChange = (editor: any, data: any, value: any) => {
        onValueChange(value)
    }

    const handleMount = (editor: any) => {
        editor.setOption('autoCloseTags', true)
        editor.setOption('autoCloseBrackets', true)
        editor.setOption('smartIndent', true)
    }

    const showHints = !readOnly || mode === 'salesforceMode'

    return (
        <ControlledCodeMirrorEditor
            className={readOnly ? 'read-only-editor-wrapper' : 'editor-wrapper'}
            value={value}
            editorDidMount={handleMount}
            onChange={(codemirror) => getSnippets(codemirror, showHints)}
            options={{
                lineWrapping: true,
                fixedGutter: true,
                mode: mode,
                indentUnit: 4,
                autoCloseTags: true,
                lineNumbers: true,
                dragDrop: true,
                readOnly: readOnly
            }}
            onBeforeChange={handleChange} />
    )
}