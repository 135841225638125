import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import './index.scss';


const theme = createTheme({
  palette: {
    text: {
      primary: "#132F4C",
    },
    primary: {
      main: '#006aff',
    },
    secondary: {
      main: '#f0a200',
    },
  },
  typography: {
    fontFamily: ['"Nunito"', 'sans-serif'].join(','),
    fontSize: 13
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
