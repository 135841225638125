import { Modal as MuiModal, Fade, Box } from '@mui/material'
import { IModal } from "../../interfaces";
import './index.scss'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: null,
    minWidth: 200,
    minHeight: 200,
    width: 'auto',
    height: 'auto',
    borderRadius: 2
};

export default function Modal(props: IModal) {
    const { onClose, open, width = 'auto', children } = { ...props }
    return (
        <MuiModal
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropProps={{
                style: {
                    background: 'rgba(255,255,255,0.48)'
                }
            }}
        >
            <Fade in={open}>
                <Box sx={{ ...style, width: width }}
                    className={'modal-container'}>
                    {children}
                </Box>
            </Fade>
        </MuiModal>
    )
}