import * as React from 'react';
import './index.scss'

export default function Tabs(props: ITabs) {
    const {tabs, onChange, value = 0} = {...props}

    const handleTagSwitch = (e: any) => {
        onChange(e, Number(e.target.id))
    }


    return (
        <div className={'tab-group-container'} onClick={handleTagSwitch}>
            {tabs.map((e: ITab) => <Tab key={e.id} {...e} activeTagId={value}/>)}
        </div>
    );
}


function Tab(props: ITab) {
    const {name, disabled, id, activeTagId} = {...props}
    let tagClass = ''
    if (disabled) tagClass = 'disabled-tab'
    if (activeTagId === id) tagClass = 'active-tab'


    return (
        <button className={tagClass} id={id.toString()}>
            {name}
        </button>
    )
}


interface ITab {
    name: string | number | JSX.Element,
    disabled?: boolean,
    id: number,
    activeTagId?: number
}

interface ITabs {
    tabs: ITab[],
    onChange: (e: any, value: number) => any,
    value: number
}

