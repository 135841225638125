import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { DonateButton } from "../../shared";
import { socialMedia, links, nithinpbCom } from "../../constants";
import nithinpbLogo from '../../assets/images/logo_bro.svg'
import './index.scss';

const excludeFooter = ['/dashboard', '/shift-er', '/shift-er/local', '/auth/salesforce']

export default function Footer() {
    const { pathname } = useLocation();

    if (pathname !== '/dashboard') {
        document.documentElement.style.setProperty('--fq-header-max-width', '1300px');
    }
    return (
        <React.Fragment>
            {
                !excludeFooter.includes(pathname) && <footer>
                    <Divider />
                    <div className={'footer-container width-max-adjust'}>
                        <Grid container justifyContent={'space-between'} spacing={3} sx={{ mb: 2 }}>
                            <NewsLetter />
                            <Menu />
                        </Grid>
                    </div>
                    <Divider />
                    <div className={'width-max-adjust'}>
                        <SmallFooter />
                    </div>
                </footer>
            }
        </React.Fragment>
    )
}

function NewsLetter() {

    return (
        <Grid item xs={12} md={6} display={'flex'} alignItems={'center'}>
            <Box sx={{ mb: 2 }}>
                {/* <DonateButton/> */}

                <Grid item xs={12} sm={12}>
                    <Typography gutterBottom sx={{ fontWeight: 600 }}> Products </Typography>
                    {
                        [
                            {
                                name: 'ForceQuery',
                                target: '_blank',
                                link: '/dashboard'
                            },
                            {
                                name: 'DataJunction',
                                target: '_blank',
                                link: '/dashboard'
                            },
                        ].map((e, index) => <Typography gutterBottom key={index} color={'text.secondary'}>
                            {e.target ?
                                <a href={e.link} target={'_blank'} className={'link'} rel="noreferrer">{e.name} </a> :
                                <Link to={e.link} className={'link'}>
                                    {e.name}
                                </Link>}
                        </Typography>)
                    }
                </Grid>

            </Box>
            {/*<Typography variant={'subtitle2'}>
                Join our news letter!
            </Typography>
            <Typography variant={'body1'}
                        gutterBottom
                        color={'textSecondary'}>
                We will only sent product updates
            </Typography>
            <div className={'news-letter'}>
                <Typography component={'label'}
                            variant={'caption'}
                            gutterBottom>
                    Enter Email
                </Typography>
                <div className={'news-letter-action'}>
                    <input placeholder={'Your email id'}/>
                    <button disabled>
                        <Typography variant={'subtitle2'}>
                            Subscribe
                        </Typography>
                    </button>
                </div>
            </div>*/}
        </Grid>
    )
}

function Menu() {
    return (
        <Grid item xs={12} md={6}>
            <Grid container justifyContent={'space-between'} spacing={3}>
                <Grid item xs={6} sm={4}>
                    <Typography gutterBottom sx={{ fontWeight: 600 }}> Resources </Typography>
                    {
                        links.map((e, index) => <Typography gutterBottom key={index} color={'text.secondary'}>
                            {e.target ?
                                <a href={e.link} target={'_blank'} className={'link'} rel="noreferrer">{e.name} </a> :
                                <Link to={e.link} className={'link'}>
                                    {e.name}
                                </Link>}
                        </Typography>)
                    }
                </Grid>
                <Grid item xs={6} sm={4}>
                    <Typography gutterBottom sx={{ fontWeight: 600 }}> Follow us </Typography>
                    {
                        socialMedia.map((e, index) => <Typography gutterBottom key={index} color={'text.secondary'}>
                            <a href={e.link} className={'link'}>
                                {e.name}
                            </a>
                        </Typography>)
                    }
                </Grid>
                <Grid item xs={6} sm={4}>
                    <Typography gutterBottom sx={{ fontWeight: 600 }}> nithinpb.com</Typography>
                    {
                        nithinpbCom.map((e, index) => <Typography gutterBottom key={index} color={'text.secondary'}>
                            <Link to={e.link} className={'link'}>
                                {e.name}
                            </Link>
                        </Typography>)
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

function SmallFooter() {
    return (
        <div className={'small-footer'}>
            <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{ mb: 2 }}>
                <img src={nithinpbLogo} alt={'nithinpb logo'} className={'footer-company-logo'} />
                <Typography color={'text.secondary'}>
                    Copyright © {new Date().getFullYear()}
                    <Typography
                        component={'span'}
                        sx={{ fontWeight: 600 }} className={'colored-text'}
                    >
                        &nbsp;nithinpb.com
                    </Typography>
                </Typography>
            </Stack>
        </div>
    )
}