import { useCallback, useEffect, useState, memo } from "react";
import { CircularProgress, Collapse, Divider, Stack } from "@mui/material";
import { useDebounce } from "use-debounce";

import { MenuGroup, MenuGroupName } from "../";
import SavedQuery from "./SavedQuery";
import { useSalesforceObject } from "../../hooks/query";

import './index.scss'

export default memo(function SideDrawer() {
    const { useSalesforceObjects } = useSalesforceObject()
    const apiSummary = useSalesforceObjects()
    const { data } = apiSummary

    const [open, setOpen] = useState<boolean>(true)
    const [menuGroupControl, setMenuGroupControl] = useState<boolean>(true)
    const [objectList, setObjectList] = useState([])

    const handleSearch = (searchKey: string) => {
        if (searchKey) {
            if (data?.length > 0) {
                const searchFn = (e: any) => `${e.name.toUpperCase()}${e.label.toUpperCase()}`.includes(searchKey.toUpperCase())
                const filterOperationResults = data.filter(searchFn)
                setObjectList(filterOperationResults)
            }
        } else {
            if (data?.length !== objectList.length) {
                setObjectList(data)
            }
        }
    }


    useEffect(() => {
        if (data?.length > 0) {
            setObjectList(data)
        }
    }, [data])


    return (
        <div className={open ? 'drawer-container-dummy drawer-expanded' : 'drawer-container-dummy'}>
            <div className={open ? 'drawer-container drawer-expanded' : 'drawer-container'}>
                <Stack spacing={1}>
                    {/* <div>
                        <MenuGroupName groupName={'Data source'} />
                        <DataSource />
                    </div>
                    <Divider /> */}
                    <div>
                        <Search onChange={handleSearch} />
                        <MenuGroupName
                            groupName={'Tables'}
                            open={menuGroupControl}
                            onChange={() => setMenuGroupControl(current => !current)}
                        />
                        <Collapse in={menuGroupControl}>
                            <MenuGroup progress={apiSummary} data={objectList || []} />
                        </Collapse>
                    </div>
                    <Divider />
                    <div>
                        <MenuGroupName groupName={'Saved Queries'} />
                        <SavedQuery />
                    </div>
                </Stack>
            </div>
        </div>
    )
})


function Search({ onChange }: { onChange: any }) {
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [debouncedText] = useDebounce(value, 500);

    const handleChange = (e: any) => {
        setLoading(true)
        setValue(e.target.value)
    }

    useEffect(() => {
        onChange(value)
        setLoading(false)
    }, [debouncedText])

    return (
        <div className={'search-wrapper'}>
            <input
                placeholder={'Search tables'}
                onChange={handleChange}
            />
            {loading && <CircularProgress className={'loading-progress'} size={18} />}
        </div>
    )
}