import { KeyboardArrowLeftRounded } from "@mui/icons-material"
import { Button, Grid } from "@mui/material"
import { TransferMethod } from ".."
import { transferMethods } from "../../constants"

export default function TransferMethods(props: ITransferMethods) {
    const { onBackClick, onTransferMethodChange } = { ...props }


    const handleOptionChange = (e: string) => {
        onTransferMethodChange(e)
    }

    const handleBackButtonClick=()=>{
        onBackClick('')
    }


    const buttonStyle = {
        height: 45,
        borderRadius: 2,
        color: 'text.secondary',
        mb:2,
    }


    return (
        <div>
            <Button sx={buttonStyle}
                disableRipple
                onClick={handleBackButtonClick}
                startIcon={<KeyboardArrowLeftRounded />}
                variant={"text"}
                color={"inherit"}>
                Back to storage provider
            </Button>
            <Grid container spacing={2}>
                {
                    transferMethods.map(e =>
                        <Grid item xs={12} lg={6} sm={6} key={e.apiName}>
                            <TransferMethod  {...e} onClick={handleOptionChange} />
                        </Grid>)
                }
            </Grid>
        </div>
    )
}

interface ITransferMethods {
    onBackClick: (e?: any) => any,
    onTransferMethodChange: (e?: any) => any,
}