import {Typography} from "@mui/material";
import './index.scss'

export default function CookiePolicy() {
    return (
        <div className={'cookie-policy-wrapper'}>
            <Typography variant={'h5'} gutterBottom>
                Terms and Policies
            </Typography>
            <Typography variant={'h6'}
                        sx={{mb: 4}}
                        gutterBottom>
                Cookie Policy
            </Typography>

            <Typography align={'justify'}>
                A cookie is a piece of software code that an internet web site sends to your browser when you access
                information at that site. A cookie is stored as a simple text file on your computer or mobile device by
                a website’s server and only that server will be able to retrieve or read the contents of that cookie.
                Cookies let you navigate between pages efficiently as they store your preferences, and generally improve
                your experience of a website. nithinpb.com and other sub products nithinpb.com use following
                types of
                cookies to enhance your experience and interactivity :
                <ul style={{marginBottom: 24, marginTop: 16}}>
                    <li>
                        <Typography variant={'subtitle1'} sx={{fontWeight: 600}} gutterBottom> Security</Typography>
                        Cookies used for security authenticate users, prevent fraud, and protect users as they interact
                        with a service.
                        Some cookies are used to authenticate users, helping ensure that only the actual owner of an
                        account can access that account.
                        Some cookies are used to prevent spam, fraud, and abuse.
                    </li>
                    <li>
                        <Typography variant={'subtitle1'} sx={{fontWeight: 600}} gutterBottom>  Non-persistent </Typography>
                        Non-persistent cookies a.k.a per-session cookies. Per-session cookies serve technical purposes
                        of
                        providing seamless navigation. These cookies do not collect personal information on users and
                        they are
                        deleted as soon as you leave our website. The cookies do not permanently record data and they
                        are not
                        stored on your computer’s hard drive. The cookies are stored in memory and are only available
                        during an
                        active browser session. Again, once you close your browser, the cookie disappears.
                    </li>
                </ul>
                You may note additionally that when you visit nithinpb.com or any sub products of nithinpb.com, you may be
                required to accept cookies. If you choose to have your browser refuse cookies, it is possible that
                nithinpb.com may not function properly.
            </Typography>
        </div>
    )
}