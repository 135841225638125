import { useEffect } from "react"
import { Typography, Box, Stack, IconButton, Tooltip } from "@mui/material"
import { LinkedIn, GitHub, ArrowDownward } from "@mui/icons-material"

import { AboutMe } from './AboutMe'
import { MyBuilds } from './MyBuilds'
import { GetInTouch } from './GetInTouch'
import { SocialMedia } from "./SocialMedia"

import './index.scss'
//@ts-ignore
import nithinPdf from '../../assets/pdf/nithinpb.com.pdf'

export default function Me() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        document.title = 'Nithin PB | About Me';
        return () => {
            document.title = 'Nithin PB | ForceQuery';
        }
    }, [])

    const titleFontProperties = { fontWeight: 600, pb: 1 }
    return (
        <div className='home-container width-max-adjust MeWrapper'>
            <SocialMedia />
            <Box>
                <Box sx={{ height: 'calc(100vh - var(--fq-header-max-height))' }} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                    <Typography sx={{ ml: '4px' }}>
                        Hi, my name is
                    </Typography>
                    <Typography
                        variant={'h3'}
                        component={'div'}
                        style={{ fontSize: 'clamp(3rem, 1.2857rem + 3.5714vw, 4rem)', letterSpacing: "-0.1rem" }}
                        color={'textPrimary'}
                        className={'colored-text'}
                        sx={{ fontWeight: 700, pb: 1 }}>
                        Nithin P B
                        <Box component={'span'}>
                            <Tooltip title={'Download resume'}>
                                <a href={nithinPdf} download={'NithinPB.pdf'} target="_blank" rel="noreferrer">
                                    <IconButton className={'resume-download-button'}>
                                        <ArrowDownward />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Box>
                    </Typography>
                    <Typography variant={'h3'}
                        style={{ fontSize: 'clamp(1.75rem, 1.1857rem + 3.3714vw, 3.75rem)', letterSpacing: "-0.1rem" }}
                        color={'textPrimary'}
                        className={'me-subheading'}
                        sx={{ ...titleFontProperties, mt: -2 }}>
                        I build things for the web and cloud.
                    </Typography>
                    <Typography sx={{ maxWidth: 600, mt: 2, ml: '4px' }}>
                        I'm a creative software engineer with a passion for building digital solutions that leave a lasting impact.
                        With expertise in front-end, back-end, and cloud development, I bring cutting-edge solutions to life.
                    </Typography>
                    <Box sx={{ mt: 3 }} gap={2} display={'flex'} className={'me-action-button'}>
                        <a href={'https://www.linkedin.com/in/nithin-p-b-a0818475/'} target={'_blank'} rel="noreferrer" >
                            <button className={'getting-started view-demo'}>
                                <Stack direction="row" alignItems={'center'} spacing={1}>
                                    <LinkedIn fontSize={'medium'} />
                                    <Typography variant='button' sx={{ textTransform: 'none' }}>
                                        LinkedIn
                                    </Typography>
                                </Stack>
                            </button>
                        </a>
                        <a href={'https://github.com/nithinpachday'} target={'_blank'} rel="noreferrer" >
                            <button className={'getting-started view-demo'}>
                                <Stack direction="row" alignItems={'center'} spacing={1}>
                                    <GitHub fontSize={'medium'} />
                                    <Typography variant='button' sx={{ textTransform: 'none' }}>
                                        GitHub
                                    </Typography>
                                </Stack>
                            </button>
                        </a>
                    </Box>
                </Box>
                <Box >
                    <AboutMe />
                </Box>
                <Box mt={10}>
                    <MyBuilds />
                </Box>
                <Box mt={5}>
                    <GetInTouch />
                </Box>
            </Box>
        </div>
    )
}