import {IconButton} from "@mui/material";
import {AddOutlined} from "@mui/icons-material";
import React from "react";

export default function NewQueryButton({onClick}: INewQueryButton) {
    return (
        <IconButton size={'small'} className={'query-button'} disableRipple onClick={onClick}>
            <AddOutlined/>
        </IconButton>
    )
}


interface INewQueryButton {
    onClick: (e: any) => any
}