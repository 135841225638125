export const links = [
    {
        name: 'Documentation',
        link:''
    },
    {
        name: 'How to use',
        link:''
    },
    {
        name: 'SOQL',
        target: '_blank',
        link:'https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql.htm'
    },
    {
        name: 'SOSL',
        target: '_blank',
        link:'https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_sosl.htm'
    }
]


export const socialMedia = [
    {
        name: 'Facebook',
        link: 'https://www.facebook.com/nithin.pachady.3'
    },
    {
        name: 'Twitter',
        link: 'https://twitter.com/nithinpb180'
    },
    {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/nithin-p-b-a0818475/'
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/nithin_pb_pachady/'
    }
]

export const nithinpbCom = [
    {
        name: 'About',
        link: '/about'
    },
    {
        name: 'Support',
        link: '/support'
    },
    {
        name: 'Cookie policy',
        link: '/cookie-policy'
    },
    {
        name: 'Terms of service',
        link: '/terms-of-service'
    }
]