import { useState } from "react";
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from "@mui/icons-material";
import { Box, Button, Divider, Stack, Step, StepLabel, Stepper } from "@mui/material";

import { ShifterObjectList, ShifterFieldList } from "..";
import './index.scss'

export default function Export() {
    const [activeStep, setActiveStep] = useState(0)


    const handleOnStageChange = (sourceEvent: 'back' | 'next') => {
        if (sourceEvent === 'next') {
            setActiveStep((current: number) => current + 1)
        }
        if (sourceEvent === 'back') {
            setActiveStep((current: number) => current - 1)
        }
    }

    return (
        <div className={'sl-e-w'}>
            <div className={"SM-Container Ex-selector-body"}>
                {activeStep === 0 && <ShifterObjectList />}
                {activeStep === 1 && <ShifterFieldList />}
            </div>
            <Box className={'stepper-position-adj'}>
                <Divider />
                <Box sx={{ mb: 3, mt: 4 }} className={"SM-Container stepper-width"}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </Box>
            <Action onNext={handleOnStageChange} onBack={handleOnStageChange} />
        </div>
    )
}



function Action(props: any) {
    const { onNext, onBack }: { onNext: any, onBack: any } = { ...props }
    return (
        <Box
            className={"bottom-footer-navigation "}
            sx={{ borderTop: 1, borderColor: 'var(--fq-border-color-1)' }}>
            <Stack
                className={"SM-Container Ex-selector-body"}
                spacing={2}
                justifyContent={'end'}
                direction={"row"}
            >
                <Button
                    startIcon={<KeyboardArrowLeftRounded />}
                    color={"inherit"}
                    variant={"contained"}
                    sx={{ borderRadius: 2 }}
                    onClick={() => onBack('back')}
                    disableElevation>
                    Cancel
                </Button>
                <Button
                    onClick={() => onNext('next')}
                    endIcon={<KeyboardArrowRightRounded />}
                    variant={"contained"}
                    sx={{ borderRadius: 2 }}
                    disableElevation>
                    Next
                </Button>
            </Stack>
        </Box>
    )
}

const steps = [
    'Select Object',
    'Field and Filters',
    'Preview',
    'Run',
];
