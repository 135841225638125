import {useState} from "react";
import {Alert, CircularProgress, Collapse, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Button, Paper, TextField} from "../../shared";
import {useForm} from "../../hooks";
import {apiErrorProgress} from "../../helper";
import service from '../../services'

const INITIAL_VALUE = {
    name: '',
    email: '',
    subject: '',
    body: ''
}

export default function ContactForm() {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const [formData, setFormData] = useForm(INITIAL_VALUE)


    const handleChange = (e: any) => {
        setFormData(e)
    }
    const sendMail = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})

            const param = {
                from: `${formData.name} <${formData.email}>`,
                subject: formData.subject,
                body: formData.body
            }
            await service.general().contactFormMail(param)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorProgress(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }
    return (
        <Paper
            variant={'elevation'}
            elevation={smallScreen ? 1 : 0}
            sx={{
                p: 2.5,
                px: smallScreen ? 4 : 0,
                pb: 0,
                margin: 'auto',
                width: 400,
                maxWidth: '100%',
                borderTop: smallScreen ? 4 : 0,
                borderColor: 'secondary.main'
            }}
        >
            <Typography variant={'h6'} color={'text.primary'} sx={{ml: 1}}>
                Let's have a small conversation
            </Typography>
            <Typography variant={'body2'} color={'text.secondary'} sx={{ml: 1}}>
                Issues and feature requests can be discussed
            </Typography>
            <Stack spacing={2} sx={{mt: 2}}>
                <TextField label={'Name'} required name={'name'} onChange={handleChange}/>
                <TextField label={'Email Id'} required name={'email'} onChange={handleChange}/>
                <TextField label={"Let's discuss about..."} required name={'subject'} onChange={handleChange}/>
                <TextField label={'Message'} multiline rows={4} required name={'body'} onChange={handleChange}/>
                <Button variant={'contained'}
                        sx={{borderRadius: 3, height: 45}}
                        disabled={apiProgress.loading}
                        disableElevation
                        onClick={sendMail}>
                    {apiProgress.loading ? <CircularProgress size={20}/> : 'SUBMIT'}
                </Button>
                <Collapse in={Boolean(apiProgress.error)}>
                    <Alert severity="error" sx={{mt: 1, borderRadius: 3}}>{apiProgress.error}</Alert>
                </Collapse>
                <Collapse in={Boolean(apiProgress.ready)}>
                    <Alert severity="success" sx={{mt: -1, borderRadius: 3}}>Message sent!</Alert>
                </Collapse>
            </Stack>
        </Paper>
    )
}