import React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from "../hooks";
import { Dashboard, Home, Authentication, CookiePolicy, TermsOfService, Shifter, ShifterMethod, Contact, Future, Me, Notepad, NotepadHome } from "../pages";
import { SalesforceCallback, SalesforceLoginForm } from "../components";
import { routes } from '../constants/routes'
import { GeneralLayout, NotepadLayout } from "../layout";

export default function MainRoute() {
    return (
        <Routes>
            {/* <Route path='/' element={<Home />} /> */}
            <Route path={'/'} element={<GeneralLayout />}>
                <Route path='' element={<Home />} />
                <Route element={<RequireAuth />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/datajunction' element={<Shifter />} />
                    <Route path='/datajunction/:storageProviderType' element={<ShifterMethod />} />
                </Route>
                <Route path='/auth' element={<Authentication />}>
                    <Route path='salesforce' element={<SalesforceLoginForm />} />
                    <Route path='salesforce/callback' element={<SalesforceCallback />} />
                </Route>
                <Route path='/contact' element={<Contact />} />
                <Route path='/me' element={<Me />} />
                <Route path='/future' element={<Future />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/terms-of-service' element={<TermsOfService />} />
            </Route>
            <Route path={'/notedrop'} element={<NotepadLayout />}>
                <Route path='' element={<NotepadHome />} />
                <Route path='/notedrop/:id' element={<Notepad />} />
            </Route>
        </Routes>
    )
}


function RequireAuth() {
    const location = useLocation();
    const { loggedIn } = useAuth()

    if (!loggedIn) {
        return <Navigate to={routes.SALESFORCE_AUTH} state={{ from: location }} />;
    }

    return <Outlet />;
}