import React from "react";
import { Grid, Typography } from "@mui/material";
import { FeatureCard } from "../../components";
import { features } from '../../constants/home'



export default function Features() {
    const fontProperty = { fontWeight: 600, fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)' }
    return (
        <div className={'width-max-adjust home-features padding-wrapper'}>
            <Typography variant={'h4'} component={'div'} sx={fontProperty}>
                The features you wanted <br />
                <Typography variant={'inherit'} component={'span'} color={'primary'} sx={fontProperty} className={'colored-text'}>
                    -we built it
                </Typography>
            </Typography>

            <Grid container spacing={3} sx={{ pt: 4 }}>
                {
                    features.map((feature, index) => <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                        <FeatureCard {...feature} />
                    </Grid>)
                }
            </Grid>
        </div>
    )
}