import {Button, Modal, Tabs, TextField} from "../../shared";
import {CircularProgress, Divider, IconButton, Stack, Typography} from "@mui/material";
import {CheckCircleOutline, CloseRounded, SaveOutlined, WbCloudyOutlined} from "@mui/icons-material";
import {useSavedQueries} from "../../hooks";
import './index.scss'
import {useEffect, useState} from "react";

const INITIAL_VALUES = {
    summary: new Date().getTime(),
    description: ''
}

const tabArray = [
    {
        name: 'Query',
        disabled: false,
        id: 0
    },
    {
        name: 'Code',
        disabled: true,
        id: 1
    }
]


export default function SaveQuery(props: ISaveQuery) {
    const {setQueries, queries} = useSavedQueries()
    const {open, onClose, queryString, queryType} = {...props}
    const [form, setForm] = useState(INITIAL_VALUES)
    const [progress, setProgress] = useState({loading: false, error: false, ready: false})

    const handleFormUpdate = (e: any) => {
        setForm({...form, [e.target.name]: e.target.value})
    }

    const onQueryLocalSave = () => {
        try {
            setProgress({loading: true, error: false, ready: false})
            const newQuery = {
                queryName: form.summary,
                description: form.description,
                queryBody: queryString,
                queryGroup: queryType || 'SOQL',
                id: new Date().getTime()
            }
            setQueries([...queries, newQuery])
            const currentQuery = JSON.parse(localStorage.getItem('fq-sq') as string) || []
            localStorage.setItem('fq-sq', JSON.stringify([...currentQuery, newQuery]))
            setProgress({loading: false, error: false, ready: true})

        } catch (e: any) {
            setProgress({loading: false, error: e.message, ready: false})
        }
    }

    useEffect(() => {
        if (open) {
            setForm({summary: new Date().getTime(), description: ''})
            setProgress({loading: false, error: false, ready: false})
        }
    }, [open])


    return (
        <Modal open={open} onClose={onClose}>
            <div className={'save-query-container'}>
                <DialogControl onClose={onClose}/>
                <Stack sx={{width: '100%'}} spacing={1.5} className={'save-query-body'}>
                    <TextField
                        label={'Call this as...'}
                        fullWidth
                        required
                        onChange={handleFormUpdate}
                        name={'summary'}
                        value={form.summary}
                    />
                    <TextField
                        label={'Description'}
                        multiline={true}
                        rows={4}
                        fullWidth
                        onChange={handleFormUpdate}
                        name={'description'}
                        value={form.description}
                    />
                    <Stack direction={'row'} spacing={2} sx={{pt: 1}} alignItems={'center'}>
                        <Button
                            sx={{borderRadius: 2, width: 80}}
                            startIcon={progress.loading ? <CircularProgress size={16}/> : <SaveOutlined/>}
                            size={'small'}
                            disabled={progress.loading}
                            onClick={onQueryLocalSave}
                            variant={'outlined'}>
                            SAVE
                        </Button>
                        <Button
                            disabled
                            size={'small'}
                            startIcon={<WbCloudyOutlined/>}>
                            SAVE CLOUD
                        </Button>
                        <Progress progress={progress}/>
                    </Stack>
                </Stack>
            </div>
        </Modal>
    )
}


function DialogControl({onClose}: { onClose: () => any }) {
    return (
        <div className={'dialog-action'}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Tabs tabs={tabArray} value={0} onChange={() => {
                }}/>
            </Stack>
            <IconButton
                onClick={onClose}
                size={'small'}>
                <CloseRounded/>
            </IconButton>
        </div>
    )
}


function Progress({progress}: { progress: any }) {
    if (progress.ready) return <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
        <CheckCircleOutline color={'success'} fontSize={'small'}/>
        <Typography variant={'body2'}>
            Saved to local
        </Typography>
    </Stack>
    if (progress.error) return <CheckCircleOutline color={'error'}/>
    return null
}

interface ISaveQuery {
    open: boolean,
    queryString: string | number | undefined | null,
    queryType: string,
    onClose: () => any
}