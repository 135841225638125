import {CircularProgress, Stack, Typography} from "@mui/material";
import {CheckCircleOutline, ErrorOutline} from "@mui/icons-material";
import {IExecutionStatus} from "../../interfaces";

export default function ExecutionStatus(props: IExecutionStatus) {
    const {executionStatus = null, loading = true} = {...props}
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {
                (!loading && executionStatus === null) &&
                <Typography color={'textSecondary'} variant={'body2'}> No executions yet!</Typography>
            }
            {
                loading && <Loading/>
            }
            {
                (!loading && executionStatus !== null) && <Status {...props}/>
            }
        </Stack>
    )
}


function Status(props: IExecutionStatus) {
    const {executionStatus = null, timeTaken = 0, totalRows = 0} = {...props}
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>

            {
                executionStatus ? <CheckCircleOutline color={'success'} fontSize={'small'}/> :
                    <ErrorOutline color={'error'} fontSize={'small'}/>
            }
            <Typography>
                {totalRows} Rows
            </Typography>
            <Typography color={'textSecondary'} variant={'body2'}>
                in {timeTaken}s
            </Typography>
        </Stack>)
}


function Loading() {
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <CircularProgress size={16}/>
            <Typography color={'textSecondary'} variant={'body2'}> Results are underway!</Typography>
        </Stack>
    )
}