import React, {memo} from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import {KeyboardArrowDownRounded, KeyboardArrowUpRounded} from "@mui/icons-material";
import {IMenuGroupName} from "../../interfaces";

export default memo(function MenuGroupName(props: IMenuGroupName) {
    const {groupName, open, onChange} = {...props}
    return <Stack direction={'row'}
                  spacing={1}
                  alignItems={'center'}>
        <Typography
            variant={'caption'}
            color={'textSecondary'}
            gutterBottom>
            {groupName}
        </Typography>
        <IconButton size={'small'} onClick={onChange}>
            {open ? <KeyboardArrowUpRounded/> : <KeyboardArrowDownRounded/>}
        </IconButton>
    </Stack>
})


