import { useEffect } from 'react';
import { useSalesforceInstance } from '.';
import { axiosPrivate, axiosPublic } from '../services/axios'

export default function useAxios() {
    const { salesforceInstance } = useSalesforceInstance()
    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(function (config: any) {
            if (!config.headers['Authorization']) {
                config.headers['x-salesforce-instance-url'] = salesforceInstance
            }
            return config;
        });

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
        }
    }, [salesforceInstance])

    return { axiosPrivate, axiosPublic }
}