import React, {useEffect, useState} from 'react'
import {AgGridReact} from 'ag-grid-react';
import GridToolBox from "./GridToolBox";
import {IDataGrid} from "../../interfaces";
import {defaultColDef} from "./defaultColDef";
import {autoSizeColumnsBasedOnData, buildGridDefinitions, autoSizeGridColumns, paginationChangeControl} from "./utls";
import './index.scss'

export default function DataGrid(props: IDataGrid) {
    const {height = '100%', gridData, fixedColumns, disableTopToolBox = false} = {...props}
    const [gridApi, setGridApi] = useState<any>(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState<any>(null);
    const [columnData, setColumn] = useState<any>(null);
    const [paginationControls, setPaginationControls] = useState({currentPage: 0, totalPage: 0})


    const onGridReady = (params: any) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        makeRowColumnData()
    }

    const onGridSizeChanged = () => {
        autoSizeColumnsBasedOnData(gridApi, gridColumnApi)
        autoSizeGridColumns(gridApi, gridColumnApi)
    }

    const onPaginationChanged = () => {
        paginationChangeControl(gridApi, setPaginationControls)
    };

    const makeRowColumnData = () => {
        const {rows, columns} = buildGridDefinitions(gridData)
        setRowData(rows)
        setColumn(columns)
    }

    useEffect(() => {
        if (gridApi) {
            makeRowColumnData()
            onGridSizeChanged()
        }
    }, [gridData])

    return (
        <div style={{height: '100%'}}>
            {!disableTopToolBox &&
            <GridToolBox gridApi={gridApi} {...paginationControls} gridColumnApi={gridColumnApi}/>}
            <div className={'ag-theme-custom-react'}
                 style={{height: `calc(${height} - 45px)`}}
                 id={'force-query-grid-wrapper'}>
                <AgGridReact
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onGridSizeChanged={onGridSizeChanged}
                    onPaginationChanged={onPaginationChanged}
                    headerHeight={40}
                    onRowDataChanged={onGridSizeChanged}
                    pagination={true}
                    animateRows={true}
                    paginationAutoPageSize={true}
                    suppressPaginationPanel={true}
                    suppressFieldDotNotation={true}
                    enableCellTextSelection={true}
                    columnDefs={fixedColumns ? fixedColumns : columnData}
                    rowData={rowData}>
                </AgGridReact>
            </div>
        </div>
    )
}