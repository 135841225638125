const comparisonOperators = {
    string: [
        {
            label: 'Equals',
            operator: '=',
            joinPosition: 'start'
        },
        {
            label: 'Not equals',
            operator: '!=',
            joinPosition: 'start'
        },
        {
            label: 'Less than',
            operator: '<',
            joinPosition: 'start'
        },
        {
            label: 'Less or equal',
            operator: '<=',
            joinPosition: 'start'
        },
        {
            label: 'Greater than',
            operator: '>',
            joinPosition: 'start'
        },
        {
            label: 'Greater or equal',
            operator: '>=',
            joinPosition: 'start'
        }, {
            label: 'Contains',
            operator: 'LIKE',
            joinPosition: 'start'
        },
        {
            label: 'IN',
            operator: 'IN',
            joinPosition: 'start'
        },
        {
            label: 'NOT IN',
            operator: 'NOT IN',
            joinPosition: 'start'
        }
    ]
}


function typeBasedOperations(type: string) {

}

export default comparisonOperators