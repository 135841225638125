import { Typography, Grid } from "@mui/material";
import { ArrowForward } from '@mui/icons-material'

import { Paper } from "../../shared";
import { Link } from "react-router-dom";

const products = [
    {
        title: 'Force Query',
        link: '/dashboard',
        description: 'ForceQuery is a sub-product of nithinpb.com that provides advanced query capabilities to easily query Salesforce data using either SOQL or SOSL without writing any code. It allows users to create complex queries using a drag-and-drop interface and provides intelligent suggestions to ensure that queries are optimized for performance. ForceQuery is designed to be user-friendly and efficient, making it easier for users to extract the data they need from Salesforce.'
    },
    {
        title: 'Data Junction',
        link: '/datajunction',
        description: "Data migration tool designed to move and transform your data seamlessly. With its user-friendly interface and robust capabilities, DataJunction simplifies the process of migrating and integrating data across multiple platforms, helping you save time and effort. Whether you're transferring data between databases or migrating to a new system, DataJunction provides the flexibility and functionality you need to ensure a smooth and hassle-free data migration process."
    }
]

export function Product() {
    const fontProperty = { fontWeight: 600, fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)' }
    return (
        <div className={'home-workflow'}>
            <div className={'width-max-adjust'}>
                <Typography variant={'subtitle2'} component={'div'} align={'center'} color={'textSecondary'}>
                    Simple steps. Easy operation! <br />
                    <Typography variant={'inherit'}
                        component={'span'}
                        color={'primary'}
                        sx={fontProperty}
                        className={'colored-text'}>
                        Effortlessly Query, Transform, and Migrate
                    </Typography>
                </Typography>

                <Grid container
                    sx={{ pt: 4 }}
                    spacing={3}>
                    {
                        products.map((e: any, index: number) => {
                            return <Card key={index} title={e.title} description={e.description} link={e.link} />
                        })
                    }
                </Grid>
            </div>
        </div>
    )
}


function Card(props: any) {
    const { link = '', title = '', description = '' } = { ...props }
    const fontProperty = { fontWeight: 600 }
    return (
        <Grid item xs={12} lg={6}>
            <Paper className={'start-now-card product-theme'}>
                <Typography gutterBottom
                    color={'textPrimary'}
                    sx={fontProperty}
                    variant={'h6'}>
                    {title}
                </Typography>
                <Typography gutterBottom
                    color={'text.primary'}
                    sx={{ mb: 2 }}
                    variant={'body1'}>
                    {description}
                </Typography>
                <Link to={link}>
                    <button className={'getting-started view-demo'}>
                        <Typography>
                            Launch
                        </Typography>
                        <ArrowForward sx={{ fontSize: 'medium', ml: 0.5 }} />
                    </button>
                </Link>
            </Paper>
        </Grid>
    )
}