import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import { PlayCircleOutlined } from "@mui/icons-material";
import DemoPlayer from "./DemoPlayer";

import { routes } from "../../constants/routes";

export default function HomeMainContent() {
    const titleFontProperties = { fontWeight: 600, pb: 1 }
    const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);

    return (
        <div className={'home-main-content'}>
            <DemoPlayer open={videoPlayerOpen} onVideoClose={setVideoPlayerOpen} />
            <Typography variant={'h3'}
                style={{ fontSize: 'clamp(3rem, 1.2857rem + 3.5714vw, 4rem)', letterSpacing: "-0.1rem" }}
                color={'textPrimary'}
                className={'colored-text'}
                sx={titleFontProperties}>
                The Ultimate Data Query and Migration Tool!
            </Typography>
            <Typography variant={'body1'}
                sx={{ mt: 1 }}
                style={{ fontSize: '1rem' }}
                gutterBottom
                color={'text.primary'}>
                <Link to={'/'} className={'non-styled-link colored-text'}>nithinpb.com</Link>
                &nbsp; simplifies Salesforce data query and migration. Extract, transform, and migrate data to other systems using a visual query editor and mapping, backup, and automation features. No code needed. Try nithinpb.com now!
            </Typography>
            <Grid
                container
                className={'button-wrapper'}
                sx={{ mt: 1.5, maxWidth: 500 }}
                alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                    <Link to={'/dashboard'}>
                        <button className={'getting-started'}>
                            <Typography variant='button'>
                                {`Try for free`}
                            </Typography>

                        </button>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <button className={'getting-started view-demo'} onClick={() => setVideoPlayerOpen(true)}>
                        <Stack direction="row" alignItems={'center'} spacing={1}>
                            <PlayCircleOutlined fontSize={'large'} />
                            <Typography variant='button'>
                                View Demo
                            </Typography>
                        </Stack>
                    </button>
                </Grid>
            </Grid>
        </div>
    )
}