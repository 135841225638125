import './index.scss'
import {Typography} from "@mui/material";
import {Paper} from "../../shared";

export default function TermsOfService() {
    return (
        <div className={'TermsOfService'}>
            <div className={'terms-container'}>
                <Typography style={{textTransform: 'uppercase'}}>
                    nithinpb.com Terms of Service
                </Typography>
                <Typography>
                    Effective January 01, 2022
                </Typography>

                <Typography variant={'h6'}
                            color={'text.secondary'}
                            sx={{mt: 2}} gutterBottom>
                    Your relationship with nithinpb.com
                </Typography>

                <Typography gutterBottom align={'justify'}>
                    These terms help define the relationship between you and nithinpb.com. Broadly speaking, we give you
                    permission to use our services if you agree to follow these terms, which reflect how nithinpb.com’s
                    business works and how we earn money. When we speak of “we,” “us,” and “our,” we mean
                    nithinpb.com and its affiliates.
                </Typography>

                <Typography variant={'h6'}
                            color={'text.secondary'}
                            sx={{mt: 2}}
                            gutterBottom>
                    Permission to use your content
                </Typography>

                <Typography align={'justify'}>
                    Some of our services are designed to let you upload, submit, store, send, receive, or share your
                    content. You have no obligation to provide any content to our services and you’re free to choose the
                    content that you want to provide. If you choose to upload or share content, please make sure you
                    have the necessary rights to do so and that the content is lawful.
                </Typography>


                <Paper sx={{backgroundColor: 'rgba(198, 198, 198, 0.27)', mt: 2, p: 3, mb: 3}}
                       variant={'elevation'}
                       elevation={0}>
                    <Typography variant={'subtitle1'} gutterBottom>LICENSE</Typography>
                    <Typography variant={'subtitle2'} gutterBottom sx={{mb:1}}>Scope</Typography>
                    <Typography gutterBottom>
                        This license is:

                        <ul>
                            <li>
                                worldwide, which means it’s valid anywhere in the world
                            </li>
                            <li>
                                non-exclusive, which means you can license your content to others
                            </li>
                            <li>
                                royalty-free, which means there are no fees for this license
                            </li>
                        </ul>
                    </Typography>
                    <Typography variant={'subtitle2'} gutterBottom sx={{mb:1}}>Rights</Typography>
                    <Typography>
                        This license allows nithinpb.com to:
                        <ul>
                            <li>
                                host, reproduce, distribute, communicate, and use your content — for example, to save your content on our systems and make it accessible from anywhere you go
                            </li>
                            <li>
                                modify and create derivative works based on your content, such as reformatting or translating it
                            </li>
                        </ul>
                    </Typography>
                </Paper>
            </div>
        </div>
    )
}