import googleDriveIcon from '../assets/images/google-drive-logo.png'
import boxIcon from '../assets/images/box-logo.png'
import oneDriveIcon from '../assets/images/onedrive-logo.svg'
import dropboxIcon from '../assets/images/dropbox-logo.png'
import localFolderIcon from '../assets/images/folder-logo.png'

const storageProvider  = [
    {
        icon: googleDriveIcon,
        apiName: 'google-drive',
        link: '/shift-er/google-drive',
        storageProviderName: 'Google Drive'
    },
    {
        icon: boxIcon,
        apiName: 'box',
        link: '/shift-er/box',
        storageProviderName: 'Box'
    },
    {
        icon: oneDriveIcon,
        apiName: 'onedrive',
        link: '/shift-er/local',
        storageProviderName: 'OneDrive'
    },
    {
        icon: dropboxIcon,
        apiName: 'dropbox',
        link: '/shift-er/dropbox',
        storageProviderName: 'Dropbox'
    },
    {
        icon: localFolderIcon,
        link: '/shift-er/local',
        apiName: 'local',
        storageProviderName: 'My PC'
    },
]

export default storageProvider