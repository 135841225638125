import axios from "axios";
import service from './'
import {apiErrorProgress} from "../helper";

axios.defaults.withCredentials = true
const axiosInstance = axios.create()

async function refreshAccessToken() {
    await service.salesforceAuth().salesforceRefreshToken()
}

axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    const errorBody = apiErrorProgress(error)

    if (errorBody === 'INVALID_SESSION_ID: Session expired or invalid' && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshAccessToken();
        return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
});


export default axiosInstance

