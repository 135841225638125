import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { meta } from '../constants'

export default function NotepadLayout() {
    const paramsData = useParams()

    useEffect(() => {
        document.title = `NoteDrop ${paramsData?.id ? "- " + paramsData?.id : ""} | Nithin P B`
        document.documentElement.style.setProperty('--fq-monospace-primary-font-color', '#2d2d2d');

        return () => {
            document.title = 'ForceQuery | Nithin P B'
            document.documentElement.style.setProperty('--fq-monospace-primary-font-color', '#a42d2d');
        }
    }, [paramsData.id])
    return (<Outlet />)
}