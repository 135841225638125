import React from "react";
import { Link, NavLink } from 'react-router-dom';
import { Stack } from "@mui/material";
import { useAuth } from "../../hooks";
import { Button } from '../../shared'
import { routes } from "../../constants/routes";
import { UserProfile } from "..";
import Logo from "../../assets/images/logo_v2.svg";
import './index.scss'

export default function Header() {
    const { loggedIn } = useAuth()

    return (
        <header>
            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} className="header-wrapper">
                <nav>
                    <NavLink to="/"> <img style={{ width: 68 }} draggable={true} src={Logo} alt="nithinpb.com logo" /></NavLink>
                    <NavLink to="/contact">Let's Connect</NavLink>
                    {/* <NavLink to="/future">Upcoming</NavLink> */}
                    {/* <NavLink to="/future">Products</NavLink> */}
                </nav>
                <div>
                    {loggedIn ? <UserProfile /> : <Login />}
                </div>
            </Stack>
        </header>
    )
}


function Login() {
    return (
        <Button>
            <Link
                style={{ textDecoration: 'none', color: "inherit" }}
                to={routes.SALESFORCE_AUTH}>
                Login
            </Link>
        </Button>
    )
}