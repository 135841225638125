import React, { useEffect, useState } from "react";
import { Collapse, IconButton, Stack, Typography } from "@mui/material";
import { KeyboardArrowDownRounded, CalendarViewMonthRounded, KeyboardArrowUpRounded } from "@mui/icons-material";

import { SMainMenu } from "../Skeletons";
import { IMainMenu } from "../../interfaces";
import SubMenu from "./SubMenu";
import { useSalesforceObject } from "../../hooks/query";

export default function MainMenu(props: IMainMenu) {
    const { label, name, custom } = { ...props }
    const { useSalesforceSingleObject } = useSalesforceObject()
    const { data = [], isLoading, isError, isSuccess, refetch } = useSalesforceSingleObject(name)

    const [expand, setExpand] = useState<boolean>(false)
    const cursor = isLoading ? 'wait' : isSuccess ? 'grab' : 'pointer'

    const handleExpand = () => {
        setExpand(current => !current)
    }

    const fetchData = async () => {
        await refetch()
    }


    const handleDrag = (e: any) => {
        let query = ''
        if (!isSuccess) query = '/* Expand the object first! No enough data to build query*/'
        else query = `SELECT ${data.map((e: any) => e.name).join(',\n       ')} \nFROM   ${name}\nLIMIT  500`
        e.dataTransfer.setData('text', query)
    }

    useEffect(() => {
        if (expand && data?.length < 1) {
            fetchData().then()
        }
    }, [expand])

    return (
        <li>
            <Stack direction={'row'}
                spacing={1}
                sx={{ cursor: cursor }}
                alignItems={'center'}>
                <IconButton size={'small'} onClick={handleExpand}>
                    {expand ? <KeyboardArrowUpRounded color={'action'} /> : <KeyboardArrowDownRounded color={'action'} />}
                </IconButton>
                <CalendarViewMonthRounded fontSize={'small'} color={'info'} />
                <Typography variant={'subtitle2'}
                    draggable
                    onDragStart={handleDrag}
                    color={'textSecondary'}
                    unselectable={'on'}>
                    {label}
                </Typography>
            </Stack>
            <Collapse in={expand}>
                {isLoading && <ul><SMainMenu /></ul>}
                {isSuccess && <SubMenu properties={data} />}
            </Collapse>
        </li>
    )
}