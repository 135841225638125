import { KeyboardArrowLeftRounded } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

export default function Navigation() {
    return (
        <div className="SM-Wrapper padding-fix-override">
            <Link to={routes.SHIFTER_HOME} className={"back-navigation"}>
                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    <KeyboardArrowLeftRounded color={'action'}/>
                    <Typography color={'text.secondary'}>
                        Back to Shift-er Home
                    </Typography>
                </Stack>
            </Link>
        </div>
    )
}