export const snippets = [{
    displayName: "FIND",
    text: "FIND"
}, {
    displayName: "INCLUDES",
    text: "INCLUDES"
}, {
    displayName: "EXCLUDES",
    text: "EXCLUDES"
}, {
    displayName: "OFFSET",
    text: "OFFSET"
}, {
    displayName: "FIELDS",
    text: "FIELDS"
}, {
    displayName: "WITH",
    text: "WITH"
}, {
    displayName: "RECORDVISIBILITYCONTEXT",
    text: "RECORDVISIBILITYCONTEXT"
}, {
    displayName: "DATA",
    text: "DATA"
}, {
    displayName: "CATEGORY",
    text: "CATEGORY"
}, {
    displayName: "ROLLUP",
    text: "ROLLUP"
}, {
    displayName: "CUBE",
    text: "CUBE"
}, {
    displayName: "GROUPING",
    text: "GROUPING"
}, {
    displayName: "HAVING",
    text: "HAVING"
}, {
    displayName: "TYPEOF",
    text: "TYPEOF"
}, {
    displayName: "VIEW",
    text: "VIEW"
}, {
    displayName: "FOR",
    text: "FOR"
}, {
    displayName: "REFERENCE",
    text: "REFERENCE"
}, {
    displayName: "AVG",
    text: "AVG"
}, {
    displayName: "COUNT_DISTINCT",
    text: "COUNT_DISTINCT"
}, {
    displayName: "DATE_IN_YEAR",
    text: "DATE_IN_YEAR"
}, {
    displayName: "DAY_ONLY",
    text: "DAY_ONLY"
}, {
    displayName: "FISCAL_MONTH",
    text: "FISCAL_MONTH"
}, {
    displayName: "FISCAL_QUARTER",
    text: "FISCAL_QUARTER"
}, {
    displayName: "FISCAL_YEAR",
    text: "FISCAL_YEAR"
}, {
    displayName: "HOUR_IN_DAY",
    text: "HOUR_IN_DAY"
}, {
    displayName: "WEEK_IN_MONTH",
    text: "WEEK_IN_MONTH"
}, {
    displayName: "WEEK_IN_YEAR",
    text: "WEEK_IN_YEAR"
}, {
    displayName: "",
    text: ""
}, {
    displayName: "CONVERTTIMEZONE",
    text: "CONVERTTIMEZONE"
}, {
    displayName: "CONVERTCURRENCY",
    text: "CONVERTCURRENCY"
}, {
    displayName: "DIVISIONFILTER",
    text: "DIVISIONFILTER"
}, {
    displayName: "SNIPPET",
    text: "SNIPPET"
}, {
    displayName: "ALTER",
    text: "ALTER"
}, {
    displayName: "AND",
    text: "AND"
}, {
    displayName: "AS",
    text: "AS"
}, {
    displayName: "ASC",
    text: "ASC"
}, {
    displayName: "BETWEEN",
    text: "BETWEEN"
}, {
    displayName: "BY",
    text: "BY"
}, {
    displayName: "COUNT",
    text: "COUNT"
}, {
    displayName: "CREATE",
    text: "CREATE"
}, {
    displayName: "DELETE",
    text: "DELETE"
}, {
    displayName: "DESC",
    text: "DESC"
}, {
    displayName: "RETURNING",
    text: "RETURNING"
}, {
    displayName: "DISTINCT",
    text: "DISTINCT"
}, {
    displayName: "DROP",
    text: "DROP"
}, {
    displayName: "FROM",
    text: "FROM"
}, {
    displayName: "GROUP",
    text: "GROUP"
}, {
    displayName: "HAVING",
    text: "HAVING"
}, {
    displayName: "IN",
    text: "IN"
}, {
    displayName: "INSERT",
    text: "INSERT"
}, {
    displayName: "INTO",
    text: "INTO"
}, {
    displayName: "IS",
    text: "IS"
}, {
    displayName: "JOIN",
    text: "JOIN"
}, {
    displayName: "LIKE",
    text: "LIKE"
}, {
    displayName: "NOT",
    text: "NOT"
}, {
    displayName: "ON",
    text: "ON"
}, {
    displayName: "OR",
    text: "OR"
}, {
    displayName: "ORDER",
    text: "ORDER"
}, {
    displayName: "SELECT",
    text: "SELECT"
}, {
    displayName: "FOR",
    text: "FOR"
}, {
    displayName: "UPDATE",
    text: "UPDATE"
}, {
    displayName: "ALL",
    text: "ALL"
}, {
    displayName: "ROWS",
    text: "ROWS"
}, {
    displayName: "SET",
    text: "SET"
}, {
    displayName: "TABLE",
    text: "TABLE"
}, {
    displayName: "UNION",
    text: "UNION"
}, {
    displayName: "UPDATE",
    text: "UPDATE"
}, {
    displayName: "VALUES",
    text: "VALUES"
}, {
    displayName: "WHERE",
    text: "WHERE"
}, {
    displayName: "LIMIT",
    text: "LIMIT"
}, {
    displayName: "YESTERDAY",
    text: "YESTERDAY"
}, {
    displayName: "TODAY",
    text: "TODAY"
}, {
    displayName: "TOMORROW",
    text: "TOMORROW"
}, {
    displayName: "LAST_WEEK",
    text: "LAST_WEEK"
}, {
    displayName: "THIS_WEEK",
    text: "THIS_WEEK"
}, {
    displayName: "NEXT_WEEK",
    text: "NEXT_WEEK"
}, {
    displayName: "LAST_MONTH",
    text: "LAST_MONTH"
}, {
    displayName: "NEXT_MONTH",
    text: "NEXT_MONTH"
}, {
    displayName: "LAST_90_DAYS",
    text: "LAST_90_DAYS"
}, {
    displayName: "NEXT_90_DAYS",
    text: "NEXT_90_DAYS"
}, {
    displayName: "LAST_N_DAYS",
    text: "LAST_N_DAYS"
}, {
    displayName: "NEXT_N_DAYS",
    text: "NEXT_N_DAYS"
}, {
    displayName: "NEXT_N_WEEKS",
    text: "NEXT_N_WEEKS"
}, {
    displayName: "NEXT_N_MONTHS",
    text: "NEXT_N_MONTHS"
}, {
    displayName: "LAST_N_MONTHS",
    text: "LAST_N_MONTHS"
}, {
    displayName: "THIS_QUARTER",
    text: "THIS_QUARTER"
}, {
    displayName: "LAST_QUARTER",
    text: "LAST_QUARTER"
}, {
    displayName: "NEXT_QUARTER",
    text: "NEXT_QUARTER"
}, {
    displayName: "NEXT_N_QUARTERS",
    text: "NEXT_N_QUARTERS"
}, {
    displayName: "LAST_N_QUARTERS",
    text: "LAST_N_QUARTERS"
}, {
    displayName: "THIS_YEAR",
    text: "THIS_YEAR"
}, {
    displayName: "LAST_YEAR",
    text: "LAST_YEAR"
}, {
    displayName: "NEXT_YEAR",
    text: "NEXT_YEAR"
}, {
    displayName: "NEXT_N_YEARS",
    text: "NEXT_N_YEARS"
}, {
    displayName: "LAST_N_YEARS",
    text: "LAST_N_YEARS"
}, {
    displayName: "THIS_FISCAL_QUARTER",
    text: "THIS_FISCAL_QUARTER"
}, {
    displayName: "LAST_FISCAL_QUARTER",
    text: "LAST_FISCAL_QUARTER"
}, {
    displayName: "NEXT_FISCAL_QUARTER",
    text: "NEXT_FISCAL_QUARTER"
}, {
    displayName: "NEXT_N_FISCAL_QUARTERS",
    text: "NEXT_N_FISCAL_QUARTERS"
}, {
    displayName: "LAST_N_FISCAL_QUARTERS",
    text: "LAST_N_FISCAL_QUARTERS"
}, {
    displayName: "THIS_FISCAL_YEAR",
    text: "THIS_FISCAL_YEAR"
}, {
    displayName: "LAST_FISCAL_YEAR",
    text: "LAST_FISCAL_YEAR"
}, {
    displayName: "NEXT_FISCAL_YEAR",
    text: "NEXT_FISCAL_YEAR"
}, {
    displayName: "NEXT_N_FISCAL_YEARS",
    text: "NEXT_N_FISCAL_YEARS"
}, {
    displayName: "LAST_N_FISCAL_YEARS",
    text: "LAST_N_FISCAL_YEARS"
}, {
    displayName: "BASE64",
    text: "BASE64"
}, {
    displayName: "ADDRESS",
    text: "ADDRESS"
}, {
    displayName: "COMBOBOX",
    text: "COMBOBOX"
}, {
    displayName: "CURRENCY",
    text: "CURRENCY"
}, {
    displayName: "LOCATION",
    text: "LOCATION"
}, {
    displayName: "TEXTAREA",
    text: "TEXTAREA"
}, {
    displayName: "URL",
    text: "URL"
}]