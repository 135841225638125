import {Grid, Stack, Typography} from "@mui/material";
import {Paper} from "../../shared";
import {BuildCircleOutlined} from "@mui/icons-material";
import './index.scss'

export default function Future() {
    return (
        <div className={'width-adjust future-wrapper'}>
            <Typography
                gutterBottom
                sx={{width: 500, fontWeight: 600, maxWidth: '100%'}}
                color={'text.secondary'}>
                Future
            </Typography>
            <Typography className={'colored-text'}
                        variant={'h3'} sx={{fontWeight: 600, width: 500, maxWidth: '100%', mb: 1.5}}>
                Let's explore the impossibles...
            </Typography>
            <Typography sx={{width: 500, maxWidth: '100%'}}>
                Upcoming feature information
            </Typography>

            <Grid container spacing={2} sx={{mt: 3}}>
                <UpComingFeatureCard
                    title={'Visual query creation'}
                    value={'Powerful visual SOQL/SOSL query builder that allows you to easily create SOSL/SOQL queries using a drag & drop interface. It provides automatic SOSQL indentation and beautification, and supports a wide range of SOQL functions for date/time calculations, aggregation, filtering and more. It has dynamic filters to filter SOSL query results on-the-fly.'}
                />
                <UpComingFeatureCard
                    title={'Shift-er data loader for salesforce'}
                    value={'web based free loader for Salesforce without any restrictions'}
                />
            </Grid>
        </div>
    )
}


function UpComingFeatureCard(props: IUpcomingFeatureCard) {
    const {title, value} = {...props}
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{p: 2}} className={'upcoming-card'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{mb:2}}>
                    <BuildCircleOutlined color={'primary'}/>
                    <Typography sx={{fontWeight: 600}}>
                        {title}
                    </Typography>
                </Stack>

                <Typography color={'text.secondary'} variant={'body2'}>
                    {value}
                </Typography>
            </Paper>
        </Grid>
    )
}

interface IUpcomingFeatureCard {
    title: string,
    value: string
}