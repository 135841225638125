import axios from "axios";
import {apiEndPoint} from "../constants/apiEndPoints";
import axiosInstance from './interceptor'

export default {
    salesforceAuth() {
        return {
            salesforceAuthUsernamePassword: (params: any) => axios.post(apiEndPoint.SALESFORCE_USERNAME_PASSWORD, params),
            salesforceOAuth: (params: any) => axios.get(apiEndPoint.SALESFORCE_OAUTH, {params}),
            salesforceOAuthIssueToken: (params: any) => axios.get(apiEndPoint.SALESFORCE_OAUTH_TOKEN, {params: params}),
            salesforceRefreshToken: () => axiosInstance.get(apiEndPoint.SALESFORCE_REFRESH_TOKEN),
            salesforceLogout: (headers: any) => axios.get(apiEndPoint.SALESFORCE_LOGOUT, {headers: headers}),
        }
    },
    salesforceOther() {
        return {
            salesforceGetAuthenticatedUser: (headers: any) => axiosInstance.get(apiEndPoint.SALESFORCE_GET_AUTH_USR, {headers: headers}),
            salesforceObjectList: (headers: any) => axiosInstance.get(apiEndPoint.SALESFORCE_LIST_OBJECTS, {headers: headers}),
            salesforceDescribeObject: (headers: any, params: any) => axiosInstance.get(apiEndPoint.SALESFORCE_DESCRIBE_OBJECT, {
                headers: headers,
                params: params
            }),
            salesforceQuery: (headers: any, params: any) => axiosInstance.post(apiEndPoint.SALESFORCE_QUERY, params, {
                headers: headers
            }),
        }
    },
    general() {
        return {
            contactFormMail: (params: any) => axios.post(apiEndPoint.CONTACT_FORM, params),
        }
    }
}